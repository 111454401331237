import React from 'react';
import { Tab, Tabs, Box } from '@mui/material';

/**
 * TabView represents a tab within the Tabs container.
 *
 * @typedef {object} TabView
 *
 * @property {string} label - The label for the tab.
 * @property {React.ReactElement} ChildTabView - A React element representing the content of the tab.

 */
export interface TabView {
  label: string;
  ChildTabView: React.ReactElement;
  description?: string;
  onPressCallback?: () => void;
}

interface IParentControlledTabSwitcherContainer {
  tabs: TabView[];
  tabIndex: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const LiftControlTabs: React.FC<IParentControlledTabSwitcherContainer> = ({
  tabs,
  tabIndex,
  handleTabChange
}: IParentControlledTabSwitcherContainer) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Tabs variant={'fullWidth'} value={tabIndex} onChange={handleTabChange}>
        {tabs.map((tab, index) => (
          <Tab key={tab.label + index.toString()} label={tab.label} />
        ))}
      </Tabs>
      <Box>{tabs[tabIndex].ChildTabView}</Box>
    </Box>
  );
};

export default LiftControlTabs;
