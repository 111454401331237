import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ZIP_CODE_REGEX, NANP_PHONE_REGEX } from 'shared/constants/regex';

const useValidationSchemas = () => {
  const { t } = useTranslation(); // Initialize the translation hook

  // Login Validation Schema
  const loginValidation = yup.object().shape({
    email: yup.string().email(t('Validations.Enter_Valid_Email')).required(t('Validations.Email_Required')),
    password: yup.string().min(8, t('Validations.Password_Min_Length')).required(t('Validations.Password_Required'))
  });

  const mfaValidation = yup.object().shape({
    verificationCode: yup.string().required(t('Validations.Required'))
  });

  // Registration Validation Schema
  const registerValidation = yup.object().shape({
    firstName: yup.string().required(t('Validations.Required')),
    lastName: yup.string().required(t('Validations.Required')),
    email: yup
      .string()
      .email(t('Validations.Email_Not_Valid'))
      .matches(
        // Improving the email validation regex. Need to test
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        t('Validations.Email_Valid')
      )
      .required(t('Validations.Required')),
    // For consistency with formatting with UserProfile, I am using the same validation tactic
    phoneNumber: yup.string().required(t('Phone_Number_Required')).matches(NANP_PHONE_REGEX, t('Invalid_phone_number')),
    companyName: yup.string(),
    countryId: yup.number().required(t('Validations.Required')),
    address: yup.string().required(t('Validations.Required')),
    address2: yup.string(),
    city: yup.string().required(t('Validations.Required')),
    stateId: yup.number().required(t('Validations.Required')),
    // This watches the value of 'countryId' and updates the validation for each different country
    zipCode: yup.string().when('countryId', {
      is: 2,
      then: (schema) =>
        schema
          .min(5, t('Dynamic_min', { min: 5 }))
          .max(5, t('Dynamic_max', { max: 5 }))
          .matches(ZIP_CODE_REGEX.unitedStates, t('Invalid_US_Zip_Code'))
          .required(t('Validations.Required')),
      otherwise: (schema) =>
        schema
          .min(6, t('Dynamic_min', { min: 6 }))
          .max(7, t('Dynamic_max', { max: 7 }))
          .matches(ZIP_CODE_REGEX.canada, t('Invalid_Canadian_Zip_Code'))
          .required(t('Validations.Required'))
    }),
    password: yup.string().required(t('Validations.Required')),
    passwordConfirm: yup
      .string()
      .required(t('Validations.Required'))
      .oneOf([yup.ref('password')], t('Validations.Password_Match'))
  });

  // Confirm Email Validation Schema
  const confirmEmailValidation = yup.object().shape({
    verificationCode: yup.string().required(t('Validations.Required'))
  });

  // Create Password Validation Schema
  const createPWDValidation = yup.object().shape({
    password: yup.string().required(t('Validations.Required')),
    passwordConfirm: yup
      .string()
      .required(t('Validations.Required'))
      .oneOf([yup.ref('password')], t('Validations.Password_Match'))
  });

  // Reset Password Validation Schema
  const resetPWDValidation = yup.object().shape({
    email: yup.string().email(t('Validations.Enter_Valid_Email')).required(t('Validations.Email_Required'))
  });

  return {
    loginValidation,
    registerValidation,
    confirmEmailValidation,
    createPWDValidation,
    resetPWDValidation,
    mfaValidation
  };
};

export default useValidationSchemas;
