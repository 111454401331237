import { TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IDeviceTabsProps {
  deviceListByType: { [key: string]: string[] };
  setCurrentTabIndex: (tabIndex: string) => void;
}

const DeviceTabs = ({ deviceListByType, setCurrentTabIndex }: IDeviceTabsProps) => {
  const { t } = useTranslation();

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, tabIndex: string) => {
    setCurrentTabIndex(tabIndex);
  };

  // Get readable tab label
  const getTabLabel = React.useCallback(
    (deviceType: string, deviceCount: number) => {
      const deviceTypeLabels: { [key: string]: string } = {
        MasterStation: t('Master_Stations'),
        SubMasterStation: t('SubMaster_Stations'),
        EntranceStation: t('Entrance_Stations'),
        TenantStation: t('Tenant_Stations'),
        GuardStation: t('Guard_Stations'),
        VideoDoorStation: t('Video_Door_Stations'),
        AudioDoorStation: t('Audio_Door_Stations'),
        EmergencyStation: t('Emergency_Stations'),
        GatewayAdaptor: t('Gateway_Adaptors'),
        IOAdaptor: t('IO_Adaptors'),
        LiftControl: t('Lift_Controllers')
      };

      const label = deviceTypeLabels[deviceType] || deviceType;
      return `${label} (${deviceCount})`;
    },
    [t]
  );

  return (
    <TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
      {
        // Render tabs
        Object.keys(deviceListByType).map((deviceType) => {
          const deviceCount = deviceListByType[deviceType] ? deviceListByType[deviceType].length : 0;
          if (deviceCount === 0) return null; // Do not show tab if there are no devices of that type
          return (
            <Tab
              key={deviceType}
              label={getTabLabel(deviceType, deviceCount)}
              value={deviceType}
              disabled={deviceCount === 0}
            />
          );
        })
      }
    </TabList>
  );
};

export default DeviceTabs;
