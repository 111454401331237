import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { RootState } from 'store';
import {
  IContactOutput,
  IDeviceContactInput,
  IDeviceDeviceSettings,
  ISifEvent,
  sifCommunicationDestinations,
  updateContactInputs,
  updateContactOutputs
} from 'store/slices/devicesSlice';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import { useTranslation } from 'react-i18next';

interface IControlPanelProps {
  needsSave: boolean;
  setNeedsSave: (value: boolean) => void;
  newRelaySettings: Array<IDeviceContactInput | IContactOutput>;
  onReset: () => void;
  isResetDisabled: boolean;
}

interface IDevicePayload {
  sitePublicId: string | undefined;
  publicId: string;
  contactInputList?: IDeviceContactInput[];
  contactOutputList?: IContactOutput[];
  deviceSettings: IDeviceDeviceSettings; // Initialize deviceSettings property
  functionSettings?: any;
}

const ControlPanel = ({ needsSave, setNeedsSave, newRelaySettings, onReset, isResetDisabled }: IControlPanelProps) => {
  const [updateDevice] = useUpdateDeviceMutation();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const devicePublicId = useParams().deviceid ?? '';
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const sitePublicId = useParams().id;
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const buttonReset = t('Reset_Button_Text');
  const buttonSaveChanges = t('Save_Changes_Button_Text');

  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');
  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');

  const buildPayload = (newRelaySettings: Array<IDeviceContactInput | IContactOutput>) => {
    // Initialize the payload with device structure
    const payload: { device: IDevicePayload } = {
      device: {
        sitePublicId: sitePublicId,
        publicId: devicePublicId,
        deviceSettings: {
          ...deviceList[devicePublicId].deviceSettings
        } // Initialize deviceSettings property
      }
    };

    // Temporary lists to hold inputs and outputs
    const contactInputList: IDeviceContactInput[] = [];
    const contactOutputList: IContactOutput[] = [];

    newRelaySettings.forEach((relay) => {
      if ('inputName' in relay) {
        contactInputList.push(relay);
      } else {
        contactOutputList.push(relay);
      }
    });

    // Only add contactInputList to payload if it has elements
    if (contactInputList.length > 0) {
      payload.device.contactInputList = contactInputList;
    }

    // Only add contactOutputList to payload if it has elements
    if (contactOutputList.length > 0) {
      payload.device.contactOutputList = contactOutputList;
    }

    return payload;
  };

  const handleSave = () => {
    const params = buildPayload(newRelaySettings);
    setIsSubmitting(true); // Set isSubmitting to true when save button is clicked
    const intercomPublicId = params.device.contactOutputList?.[0]?.sifEventSourceDevicePublicId ?? undefined;
    const previousSelectedIntercomPublicId =
      params.device.contactOutputList?.[0]?.previousSelectedDeviceId ?? undefined;

    // Create an array of promises, starting with the device update
    const promises = [
      updateDevice(params)
        .unwrap()
        .catch((error) => {
          console.error('Error in updateDevice:', error);
          return Promise.reject(error);
        }) // Update the IXW-MA device
    ];

    if (intercomPublicId) {
      promises.push(
        updatePartnerIntercom(intercomPublicId, params.device.publicId).catch((error) => {
          console.error('Error in updatePartnerIntercom:', error);
          return Promise.reject(new Error('Intercom update failed'));
        })
      );
    }
    if (previousSelectedIntercomPublicId !== undefined) {
      promises.push(
        resetPartnerIntercom(previousSelectedIntercomPublicId, null).catch((error) => {
          console.error('Error in updatePartnerIntercom:', error);
          return Promise.reject(new Error('Intercom update failed2'));
        })
      );
    }

    // Wait for all promises (device and intercom updates) to complete
    Promise.all(promises)
      .then((results) => {
        let hasError = false;

        results.forEach((result) => {
          if (result.status === 'rejected') {
            hasError = true;
            setErrorMessage(result.reason.message || 'An error occurred during the save operation.');
          }
        });
        if (!hasError) {
          setNeedsSave(false); // Mark that save is no longer needed
          setIsSubmitting(false); // Reset isSubmitting to false

          // Dispatch the actions to update the Redux state with new inputs and outputs
          dispatch(updateContactInputs(params.device.contactInputList || []));
          dispatch(updateContactOutputs(params.device.contactOutputList || []));

          // Display success message after both updates
          setSuccessMessage('Device settings saved successfully');
        } else {
          setIsSubmitting(false); // Reset isSubmitting to false
        }
      })
      .catch((error: any) => {
        const err = JSON.parse(error.data);
        if (err.errorDetails.includes('Unauthorized user Id')) {
          setErrorMessage(unauthorizedUser);
        } else {
          setErrorMessage(errorUpdateDevice);
        }
        setIsSubmitting(false); // Reset isSubmitting to false
      });
  };

  const updatePartnerIntercom = (intercomPublicId: string, targetDevicePublicId: string) => {
    const intercom = deviceList[intercomPublicId];
    const updatedSifCommunicationDestinationsList = [
      ...(intercom.functionSettings?.sifCommunicationDestinationList || [])
    ];
    if (
      !updatedSifCommunicationDestinationsList[0].targetDevicePublicId ||
      updatedSifCommunicationDestinationsList[0].targetDevicePublicId === null
    ) {
      updatedSifCommunicationDestinationsList[0] = {
        ...updatedSifCommunicationDestinationsList[0],
        targetDevicePublicId: targetDevicePublicId
      };
    } else {
      return Promise.reject(new Error('All ports are already in use'));
    }
    // Update the sifEvent array for eventType: 5
    const updatedSifEvent = [...(intercom.functionSettings?.sifEvent || [])];
    const eventIndex = updatedSifEvent.findIndex((event) => event.eventType === 5);
    updatedSifEvent[eventIndex] = {
      ...updatedSifEvent[eventIndex],
      sifDestination1: true
    };
    const sifSettings = true;
    return handleUpdateIntercom(
      intercomPublicId,
      updatedSifCommunicationDestinationsList,
      updatedSifEvent,
      sifSettings
    );
  };

  const resetPartnerIntercom = (intercomPublicId: string, targetDevicePublicId: string | null) => {
    const intercom = deviceList[intercomPublicId];
    const updatedSifCommunicationDestinationsList = [
      ...(intercom.functionSettings?.sifCommunicationDestinationList || [])
    ];
    updatedSifCommunicationDestinationsList[0] = {
      ...updatedSifCommunicationDestinationsList[0],
      targetDevicePublicId: targetDevicePublicId
    };

    return handleUpdateIntercom(intercomPublicId, updatedSifCommunicationDestinationsList);
  };

  const handleUpdateIntercom = (
    intercomPublicId: string,
    updatedSifCommunicationDestinationsList: sifCommunicationDestinations[],
    updatedSifEvent?: ISifEvent[],
    sifSettings?: boolean
  ) => {
    const intercom = deviceList[intercomPublicId];
    const intercomPayload: IDevicePayload = {
      sitePublicId: sitePublicId,
      publicId: intercomPublicId,
      deviceSettings: {
        ...deviceList[intercomPublicId].deviceSettings
      },
      functionSettings: {
        ...intercom.functionSettings,
        sifCommunicationDestinationList: updatedSifCommunicationDestinationsList,
        sifEvent: updatedSifEvent,
        sifSettings: sifSettings
      }
    };

    return updateDevice({ device: intercomPayload }).unwrap();
  };

  return (
    <>
      <SnackbarAlert
        type="success"
        time={7000}
        text={`${successMessage}`}
        isOpen={!!successMessage}
        onClose={() => setSuccessMessage('')}
      />
      <SnackbarAlert
        type="error"
        time={7000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage('')}
      />
      <Box style={styles.controlPanelWrapper}>
        <Box sx={styles.descriptionWrapper}>
          <Box sx={styles.title}>{t('Input_Output_Settings')}</Box>
          <Box sx={styles.description}> {t('Configure_Input_Output_Settings')}</Box>
        </Box>
        <Box sx={containerStyle.controlPanelWrapper}>
          <LoadingButton variant="outlined" type="reset" disabled={isResetDisabled} onClick={onReset}>
            {buttonReset}
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            type="submit"
            disabled={!needsSave || isSubmitting}
            onClick={handleSave}
            startIcon={isSubmitting ? <CircularProgress size={14} /> : null}
          >
            {buttonSaveChanges}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  controlPanelWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  buttonsWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end'
  }
};

export default ControlPanel;
