import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { COOKIE_URL, PRIVACY_URL, TERMS_URL } from './constants';
import CookieConsentBanner from 'shared/utils/CookieConsentBanner';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const AuthLayout: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const currentUser = useSelector((state: RootState) => state.users.currentUser);
  const [showCookieConsentBanner, setShowCookieConsentBanner] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(15);
  const { t } = useTranslation();

  // Check if the cookie has been accepted
  const checkCookieConsent = () => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('cookieConsent')) {
        if (cookie.split('=')[1] === 'accepted') {
          setShowCookieConsentBanner(false);
          localStorage.setItem('cookieConsent', 'accepted');
          return;
        }
      }
    }
    setShowCookieConsentBanner(true);
    return;
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    checkCookieConsent();
  }, [setShowCookieConsentBanner]);

  useEffect(() => {
    if (currentUser && timeRemaining > 0) {
      const interval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(interval);
            logout();
            return 0;
          }
        });
      }, 1000);
      return () => clearInterval(interval);
    }

    if (timeRemaining === 0) {
      logout();
    }
  }, [currentUser, timeRemaining]);

  return (
    <>
      <Box sx={styles.authWrapper}>
        <Box sx={styles.authContainer}>
          <Box sx={styles.logoWrapper}>
            <img src="/AiphoneCloud-Logo_Blue.png" alt={t('Aiphone_Logo_Alt')} width="80%" />
          </Box>
          {showCookieConsentBanner ? (
            <Box sx={{ position: 'fixed', bottom: '190px', zIndex: '900000' }}>
              <CookieConsentBanner setShowCookieConsentBanner={setShowCookieConsentBanner} />
            </Box>
          ) : (
            <Box sx={styles.formWrapper}>
              {currentUser ? (
                <Box sx={{ width: '500px' }}>
                  <Box sx={{ marginBottom: '1rem' }}>
                    <Typography variant="h5" textAlign={'center'}>
                      {t('Login_Existing_Title')}
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: '1rem' }}>
                    <Typography variant="body1" textAlign={'center'}>
                      {t('Login_Existing_Desc_1')} {timeRemaining} {t('Login_Existing_Desc_2')}
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: '1rem' }}>
                    <Typography variant="dialogSubTitle" textAlign={'center'}></Typography>
                  </Box>
                  <Button variant="contained" fullWidth onClick={() => logout()}>
                    {t('Login_Existing_Logout_Button')}
                  </Button>
                </Box>
              ) : (
                <Outlet />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Grid container sx={styles.pageFooter}>
        <Grid item>
          <Typography variant="body1" sx={{ color: '#ffffff' }}>
            <a href={COOKIE_URL} target="_blank" rel="noopener noreferrer">
              <Button>{t('Button_CookiePolicy')}</Button>
            </a>
            |
            <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
              <Button>{t('Button_PrivacyPolicy')}</Button>
            </a>
            |
            <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
              <Button>{t('Button_TermsConditions')}</Button>
            </a>
            |
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" sx={{ color: '#ffffff' }}>
            &nbsp; &copy;{currentYear} {t('Footer_CopyRight')}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  authWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100%',
    backgroundImage: `url('/login-background.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  logoWrapper: {
    padding: 3,
    textAlign: 'center'
  },
  formWrapper: {
    paddingX: 2,
    '@media (min-width: 500px)': {
      padding: 2
    }
  },
  authContainer: {
    borderRadius: '10px 10px 10px 10px',
    width: '98%',
    maxWidth: '800px',
    position: 'relative',
    boxShadow: '0 30px 60px 0 rgba(0,0,0,0.3)',
    backgroundColor: 'rgba(255, 255, 255, 0.55)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingX: 2,
    marginY: 1,
    marginBottom: '3rem'
  },
  loginLink: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#ff7500',
    textDecoration: 'none',
    cursor: 'pointer',
    marginX: 3,
    '@media (min-width: 600px)': {
      fontSize: '1.5rem',
      marginX: 10
    }
  },
  tabList: {
    '& button.Mui-selected': {
      color: '#756c6c',
      border: 'solid',
      borderColor: 'white'
    },
    '.MuiTabs-flexContainer': {
      justifyContent: 'center'
    },
    '& button:hover': {
      backgroundColor: '#f85000',
      border: 'solid',
      color: 'white'
    },
    '& button': {
      borderRadius: '5px',
      color: '#a1a1a1',
      border: 'solid',
      borderColor: '#ff7500'
    }
  },
  tabContext: {
    display: 'flex'
  },
  tabIndicator: {
    backgroundColor: '#f85000',
    height: '5px',
    borderRadius: '5px'
  },
  loginFooter: {
    backgroundColor: '#f6f6f6',
    borderTop: '1px solid #dce8f1',
    padding: '5px',
    textAlign: 'center',
    borderRadius: '0 0 10px 10px',
    color: '#ffffff'
  },
  pageFooter: {
    position: 'fixed',
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    backgroundColor: '#797979',
    width: '100%',
    height: 'auto',
    zIndex: '99',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '.25rem .25rem',
    color: 'white'
  }
};

export default AuthLayout;
