import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuikSpecSystem } from 'features/QuikSpec/Types/QuikSpecTypes';
import { StringToStringMap } from 'shared/utils/Types';

/**
 * useQuikspecFeaturedSystems is a hook that returns an array of QuikSpecSystem objects,
 * which represent featured systems with translated properties.
 *
 * This is an amalgamation of the previous FeaturedSystems and StandardSystems.
 *
 * Each system object includes:
 * - id: Unique identifier for the system.
 * - siteName: Translated name of the site.
 * - seriesName: Translated name of the series.
 * - systemforC2: Translated string for system configuration.
 * - seriesImageName: Filename of the series image.
 * - seriesDescription: Translated description of the series.
 * - seriesSummary (optional): Object containing a title and an array of translated description strings.
 *
 * @returns {QuikSpecSystem[]} Array of QuikSpecSystem objects with translated properties.
 */
export const useQuikspecFeaturedSystems = (): QuikSpecSystem[] => {
  const { t } = useTranslation();

  return [
    {
      id: 1,
      siteName: 'newIXIXGSystem',
      seriesName: t('IX_IG.seriesName'),
      systemforC2: t('IX_IG.systemforC2'),
      seriesImageName: 'IXIXG-Series.jpg',
      seriesDescription: t('IX_IG.seriesDescription'),
      seriesSummary: {
        title: t('IX_IG.seriesSummary.title'),
        descriptions: [
          t('IX_IG.seriesSummary.descriptions.first'),
          t('IX_IG.seriesSummary.descriptions.second'),
          t('IX_IG.seriesSummary.descriptions.third'),
          t('IX_IG.seriesSummary.descriptions.fourth'),
          t('IX_IG.seriesSummary.descriptions.fifth'),
          t('IX_IG.seriesSummary.descriptions.sixth')
        ]
      }
    },
    {
      id: 2,
      siteName: 'newACSystem',
      seriesName: t('AC.seriesName'),
      systemforC2: t('AC.systemforC2'),
      seriesImageName: 'AC-Series.jpg',
      seriesDescription: t('AC.seriesDescription'),
      seriesSummary: {
        title: t('AC.seriesSummary.title'),
        descriptions: [
          t('AC.seriesSummary.descriptions.first'),
          t('AC.seriesSummary.descriptions.second'),
          t('AC.seriesSummary.descriptions.third'),
          t('AC.seriesSummary.descriptions.fourth'),
          t('AC.seriesSummary.descriptions.fifth'),
          t('AC.seriesSummary.descriptions.sixth')
        ]
      }
    },
    {
      id: 3,
      siteName: 'newJPSystem',
      seriesName: t('JP.seriesName'),
      systemforC2: t('JP.systemforC2'),
      seriesImageName: 'JP-Series.jpg',
      seriesDescription: t('JP.seriesDescription'),
      seriesSummary: {
        title: t('JP.seriesSummary.title'),
        descriptions: [
          t('JP.seriesSummary.descriptions.first'),
          t('JP.seriesSummary.descriptions.second'),
          t('JP.seriesSummary.descriptions.third')
        ]
      }
    },
    {
      id: 4,
      siteName: 'newGTSystem',
      seriesName: t('GT.seriesName'),
      systemforC2: t('GT.systemforC2'),
      seriesImageName: 'GT-Series.jpg',
      seriesDescription: t('GT.seriesDescription'),
      seriesSummary: {
        title: t('GT.seriesSummary.title'),
        descriptions: [
          t('GT.seriesSummary.descriptions.first'),
          t('GT.seriesSummary.descriptions.second'),
          t('GT.seriesSummary.descriptions.third')
        ]
      }
    },
    {
      id: 5,
      siteName: 'newJOSystem',
      seriesName: t('JO.seriesName'),
      systemforC2: t('JO.systemforC2'),
      seriesImageName: 'JO-Series.jpg',
      seriesDescription: t('JO.seriesDescription'),
      seriesSummary: {
        title: t('JO.seriesSummary.title'),
        descriptions: [
          t('JO.seriesSummary.descriptions.first'),
          t('JO.seriesSummary.descriptions.second'),
          t('JO.seriesSummary.descriptions.third'),
          t('JO.seriesSummary.descriptions.fourth')
        ]
      }
    },
    {
      id: 6,
      siteName: 'newJVSystem',
      seriesName: t('JV.seriesName'),
      systemforC2: t('JV.systemforC2'),
      seriesImageName: 'JV-Series.jpg',
      seriesDescription: t('JV.seriesDescription'),
      seriesSummary: {
        title: t('JV.seriesSummary.title'),
        descriptions: [
          t('JV.seriesSummary.descriptions.first'),
          t('JV.seriesSummary.descriptions.second'),
          t('JV.seriesSummary.descriptions.third'),
          t('JV.seriesSummary.descriptions.fourth')
        ]
      }
    }
  ];
};
/**
 * Retrieves an array of standard QuikSpec systems with localized properties.
 *
 * @returns {QuikSpecSystem[]} An array of QuikSpec system objects, each containing:
 * - id: Number uniquely identifying the system.
 * - siteName: Name of the site associated with the system.
 * - seriesName: Localized name of the series.
 * - systemforC2: Localized description indicating the system's compatibility with C2.
 * - seriesImageName: File name of the series image.
 * - seriesDescription: Localized description of the series.
 */
export const useQuikSpecStandardSystems = (): QuikSpecSystem[] => {
  const { t } = useTranslation();
  return React.useMemo(
    () => [
      {
        id: 7,
        siteName: 'newAXSystem',
        seriesName: t('AX.seriesName'),
        systemforC2: t('AX.systemforC2'),
        seriesImageName: 'AX-Series.jpg',
        seriesDescription: t('AX.seriesDescription')
      },
      {
        id: 8,
        siteName: 'newKBSystem',
        seriesName: t('KB.seriesName'),
        systemforC2: t('KB.systemforC2'),
        seriesImageName: 'KB-Series.jpg',
        seriesDescription: t('KB.seriesDescription')
      },
      {
        id: 9,
        siteName: 'newNHXSystem',
        seriesName: t('NHX.seriesName'),
        systemforC2: t('NHX.systemforC2'),
        seriesImageName: 'NHX-Series.jpg',
        seriesDescription: t('NHX.seriesDescription')
      },
      {
        id: 10,
        siteName: 'newTwoWireSystem',
        seriesName: t('Two_Wire.seriesName'),
        systemforC2: t('Two_Wire.systemforC2'),
        seriesImageName: 'TWO-WIRE-Series.jpg',
        seriesDescription: t('Two_Wire.seriesDescription')
      },
      {
        id: 11,
        siteName: 'newLEFSystem',
        seriesName: t('LEF.seriesName'),
        systemforC2: t('LEF.systemforC2'),
        seriesImageName: 'LEF-Series.jpg',
        seriesDescription: t('LEF.seriesDescription')
      }
    ],
    [t]
  );
};

type ProductListViewItem = {
  description: {
    item: {
      [key: string]: string[];
    };
  };
};
/**
 * Retrieves detailed descriptions of items using the provided translation function.
 *
 * The descriptions are organized by item identifiers and contain translated strings for various attributes.
 *
 * @return {Object} An object containing item descriptions with translations.
 */
export const useQuikSpecViewDetail = (): ProductListViewItem => {
  const { t } = useTranslation();
  return React.useMemo(() => {
    return {
      description: {
        item: {
          IXMV7B: [
            t('qs_view_detail.description.item.IXMV7B.first'),
            t('qs_view_detail.description.item.IXMV7B.second'),
            t('qs_view_detail.description.item.IXMV7B.third'),
            t('qs_view_detail.description.item.IXMV7B.fourth')
          ],
          IXMV7W: [
            t('qs_view_detail.description.item.IXMV7W.first'),
            t('qs_view_detail.description.item.IXMV7W.second'),
            t('qs_view_detail.description.item.IXMV7W.third'),
            t('qs_view_detail.description.item.IXMV7W.fourth')
          ],
          IXMV7HB: [
            t('qs_view_detail.description.item.IXMV7HB.first'),
            t('qs_view_detail.description.item.IXMV7HB.second'),
            t('qs_view_detail.description.item.IXMV7HB.third'),
            t('qs_view_detail.description.item.IXMV7HB.fourth')
          ],
          IXMV7HBL: [
            t('qs_view_detail.description.item.IXMV7HBL.first'),
            t('qs_view_detail.description.item.IXMV7HBL.second'),
            t('qs_view_detail.description.item.IXMV7HBL.third'),
            t('qs_view_detail.description.item.IXMV7HBL.fourth')
          ],
          IXMV7HWJP: [
            t('qs_view_detail.description.item.IXMV7HWJP.first'),
            t('qs_view_detail.description.item.IXMV7HWJP.second'),
            t('qs_view_detail.description.item.IXMV7HWJP.third'),
            t('qs_view_detail.description.item.IXMV7HWJP.fourth')
          ],
          IXSOFT: [
            t('qs_view_detail.description.item.IXSOFT.first'),
            t('qs_view_detail.description.item.IXSOFT.second')
          ],
          IXWMASOFT: [
            t('qs_view_detail.description.item.IXWMASOFT.first'),
            t('qs_view_detail.description.item.IXWMASOFT.second'),
            t('qs_view_detail.description.item.IXWMASOFT.third')
          ],
          IXWMASOFT5: [
            t('qs_view_detail.description.item.IXWMASOFT5.first'),
            t('qs_view_detail.description.item.IXWMASOFT5.second'),
            t('qs_view_detail.description.item.IXWMASOFT5.third')
          ],
          IXWMASOFT10: [
            t('qs_view_detail.description.item.IXWMASOFT10.first'),
            t('qs_view_detail.description.item.IXWMASOFT10.second'),
            t('qs_view_detail.description.item.IXWMASOFT10.third')
          ],
          IXWMAASOFT: [
            t('qs_view_detail.description.item.IXWMAASOFT.first'),
            t('qs_view_detail.description.item.IXWMAASOFT.second'),
            t('qs_view_detail.description.item.IXWMAASOFT.third')
          ],
          IXWMAASOFT5: [
            t('qs_view_detail.description.item.IXWMAASOFT5.first'),
            t('qs_view_detail.description.item.IXWMAASOFT5.second'),
            t('qs_view_detail.description.item.IXWMAASOFT5.third')
          ],
          IXWMAASOFT10: [
            t('qs_view_detail.description.item.IXWMAASOFT10.first'),
            t('qs_view_detail.description.item.IXWMAASOFT10.second'),
            t('qs_view_detail.description.item.IXWMAASOFT10.third')
          ],
          IXRSB: [
            t('qs_view_detail.description.item.IXRSB.first'),
            t('qs_view_detail.description.item.IXRSB.second'),
            t('qs_view_detail.description.item.IXRSB.third')
          ],
          IXRSW: [
            t('qs_view_detail.description.item.IXRSW.first'),
            t('qs_view_detail.description.item.IXRSW.second'),
            t('qs_view_detail.description.item.IXRSW.third')
          ],
          IXDV: [
            t('qs_view_detail.description.item.IXDV.first'),
            t('qs_view_detail.description.item.IXDV.second'),
            t('qs_view_detail.description.item.IXDV.third')
          ],
          IXDVM: [t('qs_view_detail.description.item.IXDVM.first'), t('qs_view_detail.description.item.IXDVM.second')],
          IXEA: [
            t('qs_view_detail.description.item.IXEA.first'),
            t('qs_view_detail.description.item.IXEA.second'),
            t('qs_view_detail.description.item.IXEA.third')
          ],
          IXDVF: [
            t('qs_view_detail.description.item.IXDVF.first'),
            t('qs_view_detail.description.item.IXDVF.second'),
            t('qs_view_detail.description.item.IXDVF.third')
          ],
          IXDVFPR: [
            t('qs_view_detail.description.item.IXDVFPR.first'),
            t('qs_view_detail.description.item.IXDVFPR.second'),
            t('qs_view_detail.description.item.IXDVFPR.third')
          ],
          IXDVF4A: [
            t('qs_view_detail.description.item.IXDVF4A.first'),
            t('qs_view_detail.description.item.IXDVF4A.second'),
            t('qs_view_detail.description.item.IXDVF4A.third')
          ],
          IXDVF6: [
            t('qs_view_detail.description.item.IXDVF6.first'),
            t('qs_view_detail.description.item.IXDVF6.second'),
            t('qs_view_detail.description.item.IXDVF6.third')
          ],
          IXDVFHW: [
            t('qs_view_detail.description.item.IXDVFHW.first'),
            t('qs_view_detail.description.item.IXDVFHW.second'),
            t('qs_view_detail.description.item.IXDVFHW.third')
          ],
          IXDVFL: [
            t('qs_view_detail.description.item.IXDVFL.first'),
            t('qs_view_detail.description.item.IXDVFL.second')
          ],
          IXSS2G: [
            t('qs_view_detail.description.item.IXDVFL.first'),
            t('qs_view_detail.description.item.IXDVFL.second')
          ],
          IXSSA: [
            t('qs_view_detail.description.item.IXSSA.first'),
            t('qs_view_detail.description.item.IXSSA.second'),
            t('qs_view_detail.description.item.IXSSA.third')
          ],

          IXNVP: [t('qs_view_detail.description.item.IXNVP.first'), t('qs_view_detail.description.item.IXNVP.second')],
          IXNVP2: [
            t('qs_view_detail.description.item.IXNVP2.first'),
            t('qs_view_detail.description.item.IXNVP2.second')
          ],
          IXDVFRA: [
            t('qs_view_detail.description.item.IXDVFRA.first'),
            t('qs_view_detail.description.item.IXDVFRA.second'),
            t('qs_view_detail.description.item.IXDVFRA.third')
          ],
          IXDVF2RA: [
            t('qs_view_detail.description.item.IXDVF2RA.first'),
            t('qs_view_detail.description.item.IXDVF2RA.second'),
            t('qs_view_detail.description.item.IXDVF2RA.third')
          ],
          IXSSARA: [
            t('qs_view_detail.description.item.IXSSARA.first'),
            t('qs_view_detail.description.item.IXSSARA.second'),
            t('qs_view_detail.description.item.IXSSARA.third')
          ],
          IXSSA2RA: [
            t('qs_view_detail.description.item.IXSSA2RA.first'),
            t('qs_view_detail.description.item.IXSSA2RA.second'),
            t('qs_view_detail.description.item.IXSSA2RA.third')
          ],
          TWSA: [t('qs_view_detail.description.item.TWSA.first')],
          TWSE: [t('qs_view_detail.description.item.TWSE.first')],
          TWTA: [t('qs_view_detail.description.item.TWTA.first')],
          TWTE: [t('qs_view_detail.description.item.TWTE.first')],
          TWTAARM: [
            t('qs_view_detail.description.item.TWTAARM.first'),
            t('qs_view_detail.description.item.TWTAARM.second')
          ],
          TWTEARM: [
            t('qs_view_detail.description.item.TWTEARM.first'),
            t('qs_view_detail.description.item.TWTEARM.second')
          ],
          WBCE: [t('qs_view_detail.description.item.WBCE.first')],
          WBCA: [t('qs_view_detail.description.item.WBCA.first')],
          WBHE: [t('qs_view_detail.description.item.WBHE.first')],
          WBHA: [t('qs_view_detail.description.item.WBHA.first')],
          IPWPOE8: [t('qs_view_detail.description.item.IPWPOE8.first')],
          PSPOE: [t('qs_view_detail.description.item.PSPOE.first')],
          IXWPBXA: [t('qs_view_detail.description.item.IXWPBXA.first')],
          IXWHWCBP: [t('qs_view_detail.description.item.IXWHWCBP.first')],
          IXWMA: [t('qs_view_detail.description.item.IXWMA.first'), t('qs_view_detail.description.item.IXWMA.second')],
          IXWMAA: [
            t('qs_view_detail.description.item.IXWMAA.first'),
            t('qs_view_detail.description.item.IXWMAA.second'),
            t('qs_view_detail.description.item.IXWMAA.third')
          ],
          IPW1VC: [t('qs_view_detail.description.item.IPW1VC.first')],
          IPW10VC: [t('qs_view_detail.description.item.IPW10VC.first')],
          MH15W: [
            t('qs_view_detail.description.item.MH15W.first'),
            t('qs_view_detail.description.item.MH15W.second'),
            t('qs_view_detail.description.item.MH15W.third')
          ],
          SP2570N: [
            t('qs_view_detail.description.item.SP2570N.first'),
            t('qs_view_detail.description.item.SP2570N.second')
          ],
          SSB3: [t('qs_view_detail.description.item.SSB3.first')],
          MD35M: [t('qs_view_detail.description.item.MD35M.first')],
          MD60M: [t('qs_view_detail.description.item.MD60M.first')],
          MD120M: [t('qs_view_detail.description.item.MD120M.first')],
          IXGDM7HID: [
            t('qs_view_detail.description.item.IXGDM7HID.first'),
            t('qs_view_detail.description.item.IXGDM7HID.second'),
            t('qs_view_detail.description.item.IXGDM7HID.third'),
            t('qs_view_detail.description.item.IXGDM7HID.fourth'),
            t('qs_view_detail.description.item.IXGDM7HID.fifth'),
            t('qs_view_detail.description.item.IXGDM7HID.sixth'),
            t('qs_view_detail.description.item.IXGDM7HID.seventh'),
            t('qs_view_detail.description.item.IXGDM7HID.eighth')
          ],
          IXGDM7HIDA: [
            t('qs_view_detail.description.item.IXGDM7HIDA.first'),
            t('qs_view_detail.description.item.IXGDM7HIDA.second'),
            t('qs_view_detail.description.item.IXGDM7HIDA.third'),
            t('qs_view_detail.description.item.IXGDM7HIDA.fourth'),
            t('qs_view_detail.description.item.IXGDM7HIDA.fifth'),
            t('qs_view_detail.description.item.IXGDM7HIDA.sixth'),
            t('qs_view_detail.description.item.IXGDM7HIDA.seventh'),
            t('qs_view_detail.description.item.IXGDM7HIDA.eighth')
          ],
          IXGWTGW: [
            t('qs_view_detail.description.item.IXGWTGW.first'),
            t('qs_view_detail.description.item.IXGWTGW.second'),
            t('qs_view_detail.description.item.IXGWTGW.third')
          ],
          IXG2C7: [
            t('qs_view_detail.description.item.IXG2C7.first'),
            t('qs_view_detail.description.item.IXG2C7.second'),
            t('qs_view_detail.description.item.IXG2C7.third'),
            t('qs_view_detail.description.item.IXG2C7.fourth'),
            t('qs_view_detail.description.item.IXG2C7.fifth'),
            t('qs_view_detail.description.item.IXG2C7.sixth')
          ],
          IXG2C7L: [
            t('qs_view_detail.description.item.IXG2C7L.first'),
            t('qs_view_detail.description.item.IXG2C7L.second'),
            t('qs_view_detail.description.item.IXG2C7L.third'),
            t('qs_view_detail.description.item.IXG2C7L.fourth'),
            t('qs_view_detail.description.item.IXG2C7L.fifth'),
            t('qs_view_detail.description.item.IXG2C7L.sixth'),
            t('qs_view_detail.description.item.IXG2C7L.seventh')
          ],
          IXGMK: [
            t('qs_view_detail.description.item.IXGMK.first'),
            t('qs_view_detail.description.item.IXGMK.second'),
            t('qs_view_detail.description.item.IXGMK.third'),
            t('qs_view_detail.description.item.IXGMK.fourth'),
            t('qs_view_detail.description.item.IXGMK.fifth'),
            t('qs_view_detail.description.item.IXGMK.sixth'),
            t('qs_view_detail.description.item.IXGMK.seventh')
          ],
          IXGAPP: [
            t('qs_view_detail.description.item.IXGAPP.first'),
            t('qs_view_detail.description.item.IXGAPP.second'),
            t('qs_view_detail.description.item.IXGAPP.third')
          ],
          IXGWLC: [
            t('qs_view_detail.description.item.IXGWLC.first'),
            t('qs_view_detail.description.item.IXGWLC.second'),
            t('qs_view_detail.description.item.IXGWLC.third'),
            t('qs_view_detail.description.item.IXGWLC.fourth')
          ],
          IXGWGW: [t('qs_view_detail.description.item.IXGWGW.first')],
          ACS2DRC: [
            t('qs_view_detail.description.item.ACS2DRC.first'),
            t('qs_view_detail.description.item.ACS2DRC.second'),
            t('qs_view_detail.description.item.ACS2DRC.third'),
            t('qs_view_detail.description.item.ACS2DRC.fourth'),
            t('qs_view_detail.description.item.ACS2DRC.fifth'),
            t('qs_view_detail.description.item.ACS2DRC.sixth')
          ],
          ACCA1: [t('qs_view_detail.description.item.ACCA1.first')],
          AC2DMB: [
            t('qs_view_detail.description.item.AC2DMB.first'),
            t('qs_view_detail.description.item.AC2DMB.second'),
            t('qs_view_detail.description.item.AC2DMB.third'),
            t('qs_view_detail.description.item.AC2DMB.fourth'),
            t('qs_view_detail.description.item.AC2DMB.fifth'),
            t('qs_view_detail.description.item.AC2DMB.sixth'),
            t('qs_view_detail.description.item.AC2DMB.seventh'),
            t('qs_view_detail.description.item.AC2DMB.eighth'),
            t('qs_view_detail.description.item.AC2DMB.ninth')
          ],
          AC2DMW: [
            t('qs_view_detail.description.item.AC2DMW.first'),
            t('qs_view_detail.description.item.AC2DMW.second'),
            t('qs_view_detail.description.item.AC2DMW.third'),
            t('qs_view_detail.description.item.AC2DMW.fourth'),
            t('qs_view_detail.description.item.AC2DMW.fifth'),
            t('qs_view_detail.description.item.AC2DMW.sixth'),
            t('qs_view_detail.description.item.AC2DMW.seventh'),
            t('qs_view_detail.description.item.AC2DMW.eighth'),
            t('qs_view_detail.description.item.AC2DMW.ninth')
          ],
          AC2DMBOX: [t('qs_view_detail.description.item.AC2DMBOX.first')],
          ACSELV: [
            t('qs_view_detail.description.item.ACSELV.first'),
            t('qs_view_detail.description.item.ACSELV.second'),
            t('qs_view_detail.description.item.ACSELV.third'),
            t('qs_view_detail.description.item.ACSELV.fourth'),
            t('qs_view_detail.description.item.ACSELV.fifth'),
            t('qs_view_detail.description.item.ACSELV.sixth')
          ],
          ACSIO: [
            t('qs_view_detail.description.item.ACSIO.first'),
            t('qs_view_detail.description.item.ACSIO.second'),
            t('qs_view_detail.description.item.ACSIO.third'),
            t('qs_view_detail.description.item.ACSIO.fourth')
          ],
          ACNIOESS1: [t('qs_view_detail.description.item.ACNIOESS1.first')],
          ACNIOSB5: [t('qs_view_detail.description.item.ACNIOSB5.first')],
          ACHOST: [t('qs_view_detail.description.item.ACHOST.first')],
          ACPROX1G: [
            t('qs_view_detail.description.item.ACPROX1G.first'),
            t('qs_view_detail.description.item.ACPROX1G.second'),
            t('qs_view_detail.description.item.ACPROX1G.third')
          ],
          ACBT10K: [
            t('qs_view_detail.description.item.ACBT10K.first'),
            t('qs_view_detail.description.item.ACBT10K.second'),
            t('qs_view_detail.description.item.ACBT10K.third')
          ],
          AC10K1G: [t('qs_view_detail.description.item.AC10K1G.first')],
          ACBTM: [
            t('qs_view_detail.description.item.ACBTM.first'),
            t('qs_view_detail.description.item.ACBTM.second'),
            t('qs_view_detail.description.item.ACBTM.third')
          ],
          AC10KM: [t('qs_view_detail.description.item.AC10KM.first')],
          ACPC: [t('qs_view_detail.description.item.ACPC.first')],
          ACPF: [t('qs_view_detail.description.item.ACPF.first')],
          ACPT: [t('qs_view_detail.description.item.ACPT.first')],
          JP4MED: [
            t('qs_view_detail.description.item.JP4MED.first'),
            t('qs_view_detail.description.item.JP4MED.second'),
            t('qs_view_detail.description.item.JP4MED.third'),
            t('qs_view_detail.description.item.JP4MED.fourth'),
            t('qs_view_detail.description.item.JP4MED.fifth'),
            t('qs_view_detail.description.item.JP4MED.sixth'),
            t('qs_view_detail.description.item.JP4MED.seventh'),
            t('qs_view_detail.description.item.JP4MED.eighth')
          ],
          JP4HD: [
            t('qs_view_detail.description.item.JP4HD.first'),
            t('qs_view_detail.description.item.JP4HD.second'),
            t('qs_view_detail.description.item.JP4HD.third'),
            t('qs_view_detail.description.item.JP4HD.fourth'),
            t('qs_view_detail.description.item.JP4HD.fifth'),
            t('qs_view_detail.description.item.JP4HD.sixth'),
            t('qs_view_detail.description.item.JP4HD.seventh'),
            t('qs_view_detail.description.item.JP4HD.eighth'),
            t('qs_view_detail.description.item.JP4HD.ninth')
          ],
          MCWS_B: [
            t('qs_view_detail.description.item.MCWS_B.first'),
            t('qs_view_detail.description.item.MCWS_B.second'),
            t('qs_view_detail.description.item.MCWS_B.third'),
            t('qs_view_detail.description.item.MCWS_B.fourth'),
            t('qs_view_detail.description.item.MCWS_B.fifth')
          ],
          JPDA: [
            t('qs_view_detail.description.item.JPDA.first'),
            t('qs_view_detail.description.item.JPDA.second'),
            t('qs_view_detail.description.item.JPDA.third'),
            t('qs_view_detail.description.item.JPDA.fourth'),
            t('qs_view_detail.description.item.JPDA.fifth'),
            t('qs_view_detail.description.item.JPDA.sixth'),
            t('qs_view_detail.description.item.JPDA.seventh')
          ],
          JPDV: [
            t('qs_view_detail.description.item.JPDV.first'),
            t('qs_view_detail.description.item.JPDV.second'),
            t('qs_view_detail.description.item.JPDV.third'),
            t('qs_view_detail.description.item.JPDV.fourth'),
            t('qs_view_detail.description.item.JPDV.fifth'),
            t('qs_view_detail.description.item.JPDV.sixth'),
            t('qs_view_detail.description.item.JPDV.seventh')
          ],
          JPDVF: [
            t('qs_view_detail.description.item.JPDVF.first'),
            t('qs_view_detail.description.item.JPDVF.second'),
            t('qs_view_detail.description.item.JPDVF.third'),
            t('qs_view_detail.description.item.JPDVF.fourth'),
            t('qs_view_detail.description.item.JPDVF.fifth'),
            t('qs_view_detail.description.item.JPDVF.sixth'),
            t('qs_view_detail.description.item.JPDVF.seventh')
          ],
          JPDVFPR: [
            t('qs_view_detail.description.item.JPDVFPR.first'),
            t('qs_view_detail.description.item.JPDVFPR.second'),
            t('qs_view_detail.description.item.JPDVFPR.third'),
            t('qs_view_detail.description.item.JPDVFPR.fourth'),
            t('qs_view_detail.description.item.JPDVFPR.fifth'),
            t('qs_view_detail.description.item.JPDVFPR.sixth'),
            t('qs_view_detail.description.item.JPDVFPR.seventh'),
            t('qs_view_detail.description.item.JPDVFPR.eighth')
          ],
          IER2: [
            t('qs_view_detail.description.item.IER2.first'),
            t('qs_view_detail.description.item.IER2.second'),
            t('qs_view_detail.description.item.IER2.third')
          ],
          RYES: [t('qs_view_detail.description.item.RYES.first'), t('qs_view_detail.description.item.RYES.second')],
          GTDMBN: [
            t('qs_view_detail.description.item.GTDMBN.first'),
            t('qs_view_detail.description.item.GTDMBN.second'),
            t('qs_view_detail.description.item.GTDMBN.third')
          ],
          GTMKBN: [
            t('qs_view_detail.description.item.GTMKBN.first'),
            t('qs_view_detail.description.item.GTMKBN.second'),
            t('qs_view_detail.description.item.GTMKBN.third'),
            t('qs_view_detail.description.item.GTMKBN.fourth'),
            t('qs_view_detail.description.item.GTMKBN.fifth'),
            t('qs_view_detail.description.item.GTMKBN.sixth')
          ],
          GT1C7: [
            t('qs_view_detail.description.item.GT1C7.first'),
            t('qs_view_detail.description.item.GT1C7.second'),
            t('qs_view_detail.description.item.GT1C7.third'),
            t('qs_view_detail.description.item.GT1C7.fourth'),
            t('qs_view_detail.description.item.GT1C7.fifth'),
            t('qs_view_detail.description.item.GT1C7.sixth')
          ],
          GT1C7W: [
            t('qs_view_detail.description.item.GT1C7W.first'),
            t('qs_view_detail.description.item.GT1C7W.second'),
            t('qs_view_detail.description.item.GT1C7W.third'),
            t('qs_view_detail.description.item.GT1C7W.fourth'),
            t('qs_view_detail.description.item.GT1C7W.fifth'),
            t('qs_view_detail.description.item.GT1C7W.sixth'),
            t('qs_view_detail.description.item.GT1C7W.seventh'),
            t('qs_view_detail.description.item.GT1C7W.eighth')
          ],
          GT2C: [
            t('qs_view_detail.description.item.GT2C.first'),
            t('qs_view_detail.description.item.GT2C.second'),
            t('qs_view_detail.description.item.GT2C.third'),
            t('qs_view_detail.description.item.GT2C.fourth'),
            t('qs_view_detail.description.item.GT2C.fifth'),
            t('qs_view_detail.description.item.GT2C.sixth'),
            t('qs_view_detail.description.item.GT2C.seventh'),
            t('qs_view_detail.description.item.GT2C.eighth')
          ],
          GT1M3: [
            t('qs_view_detail.description.item.GT1M3.first'),
            t('qs_view_detail.description.item.GT1M3.second'),
            t('qs_view_detail.description.item.GT1M3.third'),
            t('qs_view_detail.description.item.GT1M3.fourth'),
            t('qs_view_detail.description.item.GT1M3.fifth'),
            t('qs_view_detail.description.item.GT1M3.sixth')
          ],
          GT1A: [
            t('qs_view_detail.description.item.GT1A.first'),
            t('qs_view_detail.description.item.GT1A.second'),
            t('qs_view_detail.description.item.GT1A.third'),
            t('qs_view_detail.description.item.GT1A.fourth')
          ],
          GT1D: [
            t('qs_view_detail.description.item.GT1D.first'),
            t('qs_view_detail.description.item.GT1D.second'),
            t('qs_view_detail.description.item.GT1D.third'),
            t('qs_view_detail.description.item.GT1D.fourth')
          ],
          GTTLIIP: [
            t('qs_view_detail.description.item.GTTLIIP.first'),
            t('qs_view_detail.description.item.GTTLIIP.second'),
            t('qs_view_detail.description.item.GTTLIIP.third'),
            t('qs_view_detail.description.item.GTTLIIP.fourth'),
            t('qs_view_detail.description.item.GTTLIIP.fifth'),
            t('qs_view_detail.description.item.GTTLIIP.sixth'),
            t('qs_view_detail.description.item.GTTLIIP.seventh'),
            t('qs_view_detail.description.item.GTTLIIP.eighth')
          ],
          GTMCX: [t('qs_view_detail.description.item.GTMCX.first')],
          GTRY: [t('qs_view_detail.description.item.GTRY.first')],
          GTWLC: [
            t('qs_view_detail.description.item.GTWLC.first'),
            t('qs_view_detail.description.item.GTWLC.second'),
            t('qs_view_detail.description.item.GTWLC.third')
          ],
          GTWVOA: [t('qs_view_detail.description.item.GTWVOA.first')],
          JO1MDW: [
            t('qs_view_detail.description.item.JO1MDW.first'),
            t('qs_view_detail.description.item.JO1MDW.second'),
            t('qs_view_detail.description.item.JO1MDW.third'),
            t('qs_view_detail.description.item.JO1MDW.fourth')
          ],
          JO1FD: [
            t('qs_view_detail.description.item.JO1FD.first'),
            t('qs_view_detail.description.item.JO1FD.second'),
            t('qs_view_detail.description.item.JO1FD.third')
          ],
          JODA: [
            t('qs_view_detail.description.item.JODA.first'),
            t('qs_view_detail.description.item.JODA.second'),
            t('qs_view_detail.description.item.JODA.third'),
            t('qs_view_detail.description.item.JODA.fourth')
          ],
          JODV: [
            t('qs_view_detail.description.item.JODV.first'),
            t('qs_view_detail.description.item.JODV.second'),
            t('qs_view_detail.description.item.JODV.third'),
            t('qs_view_detail.description.item.JODV.fourth')
          ],
          JODVF: [
            t('qs_view_detail.description.item.JODVF.first'),
            t('qs_view_detail.description.item.JODVF.second'),
            t('qs_view_detail.description.item.JODVF.third'),
            t('qs_view_detail.description.item.JODVF.fourth')
          ],
          AXA: [t('qs_view_detail.description.item.AXA.first')],
          AXAN: [t('qs_view_detail.description.item.AXAN.first')],
          AXBN: [t('qs_view_detail.description.item.AXBN.first')],
          LEF3: [
            t('qs_view_detail.description.item.LEF3.first'),
            t('qs_view_detail.description.item.LEF3.second'),
            t('qs_view_detail.description.item.LEF3.third'),
            t('qs_view_detail.description.item.LEF3.fourth'),
            t('qs_view_detail.description.item.LEF3.fifth'),
            t('qs_view_detail.description.item.LEF3.sixth')
          ],
          LEF3L: [
            t('qs_view_detail.description.item.LEF3L.first'),
            t('qs_view_detail.description.item.LEF3L.second'),
            t('qs_view_detail.description.item.LEF3L.third'),
            t('qs_view_detail.description.item.LEF3L.fourth'),
            t('qs_view_detail.description.item.LEF3L.fifth'),
            t('qs_view_detail.description.item.LEF3L.sixth'),
            t('qs_view_detail.description.item.LEF3L.seventh'),
            t('qs_view_detail.description.item.LEF3L.eighth')
          ],
          LEF3C: [
            t('qs_view_detail.description.item.LEF3C.first'),
            t('qs_view_detail.description.item.LEF3C.second'),
            t('qs_view_detail.description.item.LEF3C.third'),
            t('qs_view_detail.description.item.LEF3C.fourth'),
            t('qs_view_detail.description.item.LEF3C.fifth'),
            t('qs_view_detail.description.item.LEF3C.sixth')
          ],
          LEF5: [
            t('qs_view_detail.description.item.LEF5.first'),
            t('qs_view_detail.description.item.LEF5.second'),
            t('qs_view_detail.description.item.LEF5.third'),
            t('qs_view_detail.description.item.LEF5.fourth'),
            t('qs_view_detail.description.item.LEF5.fifth'),
            t('qs_view_detail.description.item.LEF5.sixth'),
            t('qs_view_detail.description.item.LEF5.seventh'),
            t('qs_view_detail.description.item.LEF5.eighth'),
            t('qs_view_detail.description.item.LEF5.ninth')
          ],
          LEF5C: [
            t('qs_view_detail.description.item.LEF5C.first'),
            t('qs_view_detail.description.item.LEF5C.second'),
            t('qs_view_detail.description.item.LEF5C.third'),
            t('qs_view_detail.description.item.LEF5C.fourth'),
            t('qs_view_detail.description.item.LEF5C.fifth'),
            t('qs_view_detail.description.item.LEF5C.sixth'),
            t('qs_view_detail.description.item.LEF5C.seventh'),
            t('qs_view_detail.description.item.LEF5C.eighth'),
            t('qs_view_detail.description.item.LEF5C.ninth'),
            t('qs_view_detail.description.item.LEF5C.tenth'),
            t('qs_view_detail.description.item.LEF5C.eleventh')
          ],
          LEF10: [
            t('qs_view_detail.description.item.LEF10.first'),
            t('qs_view_detail.description.item.LEF10.second'),
            t('qs_view_detail.description.item.LEF10.third'),
            t('qs_view_detail.description.item.LEF10.fourth'),
            t('qs_view_detail.description.item.LEF10.fifth'),
            t('qs_view_detail.description.item.LEF10.sixth'),
            t('qs_view_detail.description.item.LEF10.seventh'),
            t('qs_view_detail.description.item.LEF10.eighth')
          ],
          LEF10S: [
            t('qs_view_detail.description.item.LEF10S.first'),
            t('qs_view_detail.description.item.LEF10S.second'),
            t('qs_view_detail.description.item.LEF10S.third'),
            t('qs_view_detail.description.item.LEF10S.fourth'),
            t('qs_view_detail.description.item.LEF10S.fifth'),
            t('qs_view_detail.description.item.LEF10S.sixth'),
            t('qs_view_detail.description.item.LEF10S.seventh'),
            t('qs_view_detail.description.item.LEF10S.eighth'),
            t('qs_view_detail.description.item.LEF10S.ninth'),
            t('qs_view_detail.description.item.LEF10S.tenth'),
            t('qs_view_detail.description.item.LEF10S.eleventh')
          ],
          LEF10C: [
            t('qs_view_detail.description.item.LEF10C.first'),
            t('qs_view_detail.description.item.LEF10C.second'),
            t('qs_view_detail.description.item.LEF10C.third'),
            t('qs_view_detail.description.item.LEF10C.fourth'),
            t('qs_view_detail.description.item.LEF10C.fifth'),
            t('qs_view_detail.description.item.LEF10C.sixth'),
            t('qs_view_detail.description.item.LEF10C.seventh'),
            t('qs_view_detail.description.item.LEF10C.eighth'),
            t('qs_view_detail.description.item.LEF10C.ninth'),
            t('qs_view_detail.description.item.LEF10C.tenth'),
            t('qs_view_detail.description.item.LEF10C.eleventh')
          ],
          LEA: [
            t('qs_view_detail.description.item.LEA.first'),
            t('qs_view_detail.description.item.LEA.second'),
            t('qs_view_detail.description.item.LEA.third')
          ],
          LEAN: [
            t('qs_view_detail.description.item.LEAN.first'),
            t('qs_view_detail.description.item.LEAN.second'),
            t('qs_view_detail.description.item.LEAN.third'),
            t('qs_view_detail.description.item.LEAN.fourth')
          ],
          LEB: [
            t('qs_view_detail.description.item.LEB.first'),
            t('qs_view_detail.description.item.LEB.second'),
            t('qs_view_detail.description.item.LEB.third')
          ],
          LEBN: [
            t('qs_view_detail.description.item.LEBN.first'),
            t('qs_view_detail.description.item.LEBN.second'),
            t('qs_view_detail.description.item.LEBN.third'),
            t('qs_view_detail.description.item.LEBN.fourth')
          ],
          LEF1C: [
            t('qs_view_detail.description.item.LEF1C.first'),
            t('qs_view_detail.description.item.LEF1C.second'),
            t('qs_view_detail.description.item.LEF1C.third'),
            t('qs_view_detail.description.item.LEF1C.fourth'),
            t('qs_view_detail.description.item.LEF1C.fifth')
          ],
          LED: [
            t('qs_view_detail.description.item.LED.first'),
            t('qs_view_detail.description.item.LED.second'),
            t('qs_view_detail.description.item.LED.third'),
            t('qs_view_detail.description.item.LED.fourth'),
            t('qs_view_detail.description.item.LED.fifth')
          ],
          LESS1G: [
            t('qs_view_detail.description.item.LESS1G.first'),
            t('qs_view_detail.description.item.LESS1G.second'),
            t('qs_view_detail.description.item.LESS1G.third'),
            t('qs_view_detail.description.item.LESS1G.fourth'),
            t('qs_view_detail.description.item.LESS1G.fifth'),
            t('qs_view_detail.description.item.LESS1G.sixth')
          ],
          LEDA: [
            t('qs_view_detail.description.item.LEDA.first'),
            t('qs_view_detail.description.item.LEDA.second'),
            t('qs_view_detail.description.item.LEDA.third'),
            t('qs_view_detail.description.item.LEDA.fourth')
          ],
          LESS_A: [
            t('qs_view_detail.description.item.LESS_A.first'),
            t('qs_view_detail.description.item.LESS_A.second'),
            t('qs_view_detail.description.item.LESS_A.third'),
            t('qs_view_detail.description.item.LESS_A.fourth'),
            t('qs_view_detail.description.item.LESS_A.fifth'),
            t('qs_view_detail.description.item.LESS_A.sixth')
          ],
          LESSR: [
            t('qs_view_detail.description.item.LESSR.first'),
            t('qs_view_detail.description.item.LESSR.second'),
            t('qs_view_detail.description.item.LESSR.third'),
            t('qs_view_detail.description.item.LESSR.fourth'),
            t('qs_view_detail.description.item.LESSR.fifth'),
            t('qs_view_detail.description.item.LESSR.sixth')
          ],
          LSNVP_C: [
            t('qs_view_detail.description.item.LSNVP_C.first'),
            t('qs_view_detail.description.item.LSNVP_C.second'),
            t('qs_view_detail.description.item.LSNVP_C.third'),
            t('qs_view_detail.description.item.LSNVP_C.fourth'),
            t('qs_view_detail.description.item.LSNVP_C.fifth')
          ],
          BG10C: [
            t('qs_view_detail.description.item.BG10C.first'),
            t('qs_view_detail.description.item.BG10C.second'),
            t('qs_view_detail.description.item.BG10C.third'),
            t('qs_view_detail.description.item.BG10C.fourth'),
            t('qs_view_detail.description.item.BG10C.fifth'),
            t('qs_view_detail.description.item.BG10C.sixth')
          ],
          BA1: [
            t('qs_view_detail.description.item.BA1.first'),
            t('qs_view_detail.description.item.BA1.second'),
            t('qs_view_detail.description.item.BA1.third')
          ],
          RYPA: [t('qs_view_detail.description.item.RYPA.first'), t('qs_view_detail.description.item.RYPA.second')],
          RYAC_A: [
            t('qs_view_detail.description.item.RYAC_A.first'),
            t('qs_view_detail.description.item.RYAC_A.second')
          ],
          NP25V: [t('qs_view_detail.description.item.NP25V.first'), t('qs_view_detail.description.item.NP25V.second')]
        }
      }
    };
  }, [t]);
};

/**
 * Retrieves a list of product details using the provided translation keys.
 * Each product is identified by a unique key which is translated into a detailed description.
 *
 * @return {Object} An object containing key-value pairs where the key is the product identifier and the value is the translated product detail.
 */
export const useQuikSpecProductList = (): object => {
  const { t } = useTranslation();

  return React.useMemo(
    () => ({
      '82180310C': t('qs_productlist.82180310C.detail'),
      '82220210C': t('qs_productlist.82220210C.detail'),
      '82220350C': t('qs_productlist.82220350C.detail'),
      '82221050C': t('qs_productlist.82221050C.detail'),
      '82221250C': t('qs_productlist.82221250C.detail'),
      '82221550C': t('qs_productlist.82221550C.detail'),
      '82222050C': t('qs_productlist.82222050C.detail'),
      AC10S: t('qs_productlist.AC10S.detail'),
      AC2DE: t('qs_productlist.AC2DE.detail'),
      AC2DMB: t('qs_productlist.AC2DMB.detail'),
      AC2DMBOX: t('qs_productlist.AC2DMBOX.detail'),
      AC2DMW: t('qs_productlist.AC2DMW.detail'),
      ACC: t('qs_productlist.ACC.detail'),
      ACCA1: t('qs_productlist.ACCA1.detail'),
      ACHOST: t('qs_productlist.ACHOST.detail'),
      ACIOE: t('qs_productlist.ACIOE.detail'),
      ACNIOESS1: t('qs_productlist.ACNIOESS1.detail'),
      ACNIOESS5: t('qs_productlist.ACNIOESS5.detail'),
      ACNIOSB1: t('qs_productlist.ACNIOSB1.detail'),
      ACNIOSB5: t('qs_productlist.ACNIOSB5.detail'),
      ACPC40: t('qs_productlist.ACPC40.detail'),
      ACPCH26: t('qs_productlist.ACPCH26.detail'),
      ACPF40: t('qs_productlist.ACPF40.detail'),
      ACPFH26: t('qs_productlist.ACPFH26.detail'),
      ACPROX1G: t('qs_productlist.ACPROX1G.detail'),
      ACPS1250: t('qs_productlist.ACPS1250.detail'),
      ACPT40: t('qs_productlist.ACPT40.detail'),
      ACPTH26: t('qs_productlist.ACPTH26.detail'),
      ACS2DR: t('qs_productlist.ACS2DR.detail'),
      ACS2DRC: t('qs_productlist.ACS2DRC.detail'),
      ACSELV: t('qs_productlist.ACSELV.detail'),
      ACSIO: t('qs_productlist.ACSIO.detail'),
      ACBT10K: t('qs_productlist.ACBT10K.detail'),
      AC10K1G: t('qs_productlist.AC10K1G.detail'),
      ACBTM: t('qs_productlist.ACBTM.detail'),
      AC10KM: t('qs_productlist.AC10KM.detail'),
      AX084C: t('qs_productlist.AX084C.detail'),
      AX16SW: t('qs_productlist.AX16SW.detail'),
      AX248C: t('qs_productlist.AX248C.detail'),
      AX320C: t('qs_productlist.AX320C.detail'),
      AX8M: t('qs_productlist.AX8M.detail'),
      AX8MV: t('qs_productlist.AX8MV.detail'),
      AXA: t('qs_productlist.AXA.detail'),
      AXAN: t('qs_productlist.AXAN.detail'),
      AXB: t('qs_productlist.AXB.detail'),
      AXBN: t('qs_productlist.AXBN.detail'),
      AXDM: t('qs_productlist.AXDM.detail'),
      AXDV: t('qs_productlist.AXDV.detail'),
      AXDVF: t('qs_productlist.AXDVF.detail'),
      AXDVFP: t('qs_productlist.AXDVFP.detail'),
      AXDVP: t('qs_productlist.AXDVP.detail'),
      BA1: t('qs_productlist.BA1.detail'),
      BBX1E: t('qs_productlist.BBX1E.detail'),
      BBX3E: t('qs_productlist.BBX3E.detail'),
      BG10C: t('qs_productlist.BG10C.detail'),
      C123L_A: t('qs_productlist.C123L_A.detail'),
      C123LW: t('qs_productlist.C123LW.detail'),
      CCS1A: t('qs_productlist.CCS1A.detail'),
      CML_A: t('qs_productlist.CML_A.detail'),
      DA1DS: t('qs_productlist.DA1DS.detail'),
      DA2DS: t('qs_productlist.DA2DS.detail'),
      DA4DS: t('qs_productlist.DA4DS.detail'),
      DAK2S: t('qs_productlist.DAK2S.detail'),
      DB1MD: t('qs_productlist.DB1MD.detail'),
      DB1SD: t('qs_productlist.DB1SD.detail'),
      EL12S: t('qs_productlist.EL12S.detail'),
      GF10KP: t('qs_productlist.GF10KP.detail'),
      GF1P: t('qs_productlist.GF1P.detail'),
      GF2B: t('qs_productlist.GF2B.detail'),
      GF2F: t('qs_productlist.GF2F.detail'),
      GF2P: t('qs_productlist.GF2P.detail'),
      GF3B: t('qs_productlist.GF3B.detail'),
      GF3F: t('qs_productlist.GF3F.detail'),
      GF3P: t('qs_productlist.GF3P.detail'),
      GF4P: t('qs_productlist.GF4P.detail'),
      GFBP: t('qs_productlist.GFBP.detail'),
      GFKPS: t('qs_productlist.GFKPS.detail'),
      GT102H: t('qs_productlist.GT102H.detail'),
      GT102HB: t('qs_productlist.GT102HB.detail'),
      GT103H: t('qs_productlist.GT103H.detail'),
      GT103HB: t('qs_productlist.GT103HB.detail'),
      GT10K: t('qs_productlist.GT10K.detail'),
      GT1A: t('qs_productlist.GT1A.detail'),
      GT1C7: t('qs_productlist.GT1C7.detail'),
      GT1C7W: t('qs_productlist.GT1C7W.detail'),
      GT1D: t('qs_productlist.GT1D.detail'),
      GT1M3: t('qs_productlist.GT1M3.detail'),
      GT202H: t('qs_productlist.GT202H.detail'),
      GT202HB: t('qs_productlist.GT202HB.detail'),
      GT203H: t('qs_productlist.GT203H.detail'),
      GT203HB: t('qs_productlist.GT203HB.detail'),
      GT2C: t('qs_productlist.GT2C.detail'),
      GT2H: t('qs_productlist.GT2H.detail'),
      GT4Z: t('qs_productlist.GT4Z.detail'),
      GT4ZP: t('qs_productlist.GT4ZP.detail'),
      GTADESB: t('qs_productlist.GTADESB.detail'),
      GTBC: t('qs_productlist.GTBC.detail'),
      GTBCXBN: t('qs_productlist.GTBCXBN.detail'),
      GTD: t('qs_productlist.GTD.detail'),
      GTDB: t('qs_productlist.GTDB.detail'),
      GTDBP: t('qs_productlist.GTDBP.detail'),
      GTDBVN: t('qs_productlist.GTDBVN.detail'),
      GTDMBN: t('qs_productlist.GTDMBN.detail'),
      GTHS: t('qs_productlist.GTHS.detail'),
      GTHSA: t('qs_productlist.GTHSA.detail'),
      GTMCX: t('qs_productlist.GTMCX.detail'),
      GTMKBN: t('qs_productlist.GTMKBN.detail'),
      GTNSB: t('qs_productlist.GTNSB.detail'),
      GTNSPL: t('qs_productlist.GTNSPL.detail'),
      GTRY: t('qs_productlist.GTRY.detail'),
      GTSW: t('qs_productlist.GTSW.detail'),
      GTTLIIP: t('qs_productlist.GTTLIIP.detail'),
      GTVB: t('qs_productlist.GTVB.detail'),
      GTVBC: t('qs_productlist.GTVBC.detail'),
      GTVBX: t('qs_productlist.GTVBX.detail'),
      GTVDES202B: t('qs_productlist.GTVDES202B.detail'),
      GTVP: t('qs_productlist.GTVP.detail'),
      GTWDP: t('qs_productlist.GTWDP.detail'),
      GTWLC: t('qs_productlist.GTWLC.detail'),
      GTWVOA: t('qs_productlist.GTWVOA.detail'),
      IE1GD: t('qs_productlist.IE1GD.detail'),
      IE2AD: t('qs_productlist.IE2AD.detail'),
      IE8HD: t('qs_productlist.IE8HD.detail'),
      IE8MD: t('qs_productlist.IE8MD.detail'),
      IEH1CD: t('qs_productlist.IEH1CD.detail'),
      IEJA: t('qs_productlist.IEJA.detail'),
      IER2: t('qs_productlist.IER2.detail'),
      IESS_A: t('qs_productlist.IESS_A.detail'),
      IESSR: t('qs_productlist.IESSR.detail'),
      IFDA: t('qs_productlist.IFDA.detail'),
      IPW1VC: t('qs_productlist.IPW1VC.detail'),
      IPW1VT: t('qs_productlist.IPW1VT.detail'),
      IPW10VC: t('qs_productlist.IPW10VC.detail'),
      IPW10VR: t('qs_productlist.IPW10VR.detail'),
      IPWPOE8: t('qs_productlist.IPWPOE8.detail'),
      IPWVPN: t('qs_productlist.IPWVPN.detail'),
      ISCCU: t('qs_productlist.ISCCU.detail'),
      ISDV: t('qs_productlist.ISDV.detail'),
      ISDVF: t('qs_productlist.ISDVF.detail'),
      ISDVF2RA: t('qs_productlist.ISDVF2RA.detail'),
      ISDVFHID: t('qs_productlist.ISDVFHID.detail'),
      ISDVFRP10: t('qs_productlist.ISDVFRP10.detail'),
      ISIPC: t('qs_productlist.ISIPC.detail'),
      ISIPDV: t('qs_productlist.ISIPDV.detail'),
      ISIPDVF: t('qs_productlist.ISIPDVF.detail'),
      ISIPMV: t('qs_productlist.ISIPMV.detail'),
      ISMV: t('qs_productlist.ISMV.detail'),
      ISPUUL: t('qs_productlist.ISPUUL.detail'),
      ISRACK: t('qs_productlist.ISRACK.detail'),
      ISRCU: t('qs_productlist.ISRCU.detail'),
      ISRS: t('qs_productlist.ISRS.detail'),
      ISSCU: t('qs_productlist.ISSCU.detail'),
      ISSOFT: t('qs_productlist.ISSOFT.detail'),
      ISSS: t('qs_productlist.ISSS.detail'),
      ISSS2G: t('qs_productlist.ISSS2G.detail'),
      ISSS2RA: t('qs_productlist.ISSS2RA.detail'),
      ISSS2RAR: t('qs_productlist.ISSS2RAR.detail'),
      ISSSCI: t('qs_productlist.ISSSCI.detail'),
      ISSSRA: t('qs_productlist.ISSSRA.detail'),
      ISSSRAR: t('qs_productlist.ISSSRAR.detail'),
      ISWBCA: t('qs_productlist.ISWBCA.detail'),
      ISWBCE: t('qs_productlist.ISWBCE.detail'),
      ISWBHA: t('qs_productlist.ISWBHA.detail'),
      ISWBHE: t('qs_productlist.ISWBHE.detail'),
      IX_MOBILE: t('qs_productlist.IX_MOBILE.detail'),
      IX10AS: t('qs_productlist.IX10AS.detail'),
      IX1AS: t('qs_productlist.IX1AS.detail'),
      IXBA: t('qs_productlist.IXBA.detail'),
      IXDA: t('qs_productlist.IXDA.detail'),
      IXDF: t('qs_productlist.IXDF.detail'),
      IXDF2RA: t('qs_productlist.IXDF2RA.detail'),
      IXDFHID: t('qs_productlist.IXDFHID.detail'),
      IXDFRP10: t('qs_productlist.IXDFRP10.detail'),
      IXDV: t('qs_productlist.IXDV.detail'),
      IXDVF: t('qs_productlist.IXDVF.detail'),
      IXDVF2RA: t('qs_productlist.IXDVF2RA.detail'),
      IXDVF4A: t('qs_productlist.IXDVF4A.detail'),
      IXDVF6: t('qs_productlist.IXDVF6.detail'),
      IXDVFHW: t('qs_productlist.IXDVFHW.detail'),
      IXDVFL: t('qs_productlist.IXDVFL.detail'),
      IXDVFPR: t('qs_productlist.IXDVFPR.detail'),
      IXDVFRA: t('qs_productlist.IXDVFRA.detail'),
      IXDVM: t('qs_productlist.IXDVM.detail'),
      IXEA: t('qs_productlist.IXEA.detail'),
      IXG_Mobile_App: t('qs_productlist.IXG_Mobile_App.detail'),
      IXGAPP: t('qs_productlist.IXGAPP.detail'),
      IXG2C7: t('qs_productlist.IXG2C7.detail'),
      IXG2C7L: t('qs_productlist.IXG2C7L.detail'),
      IXGDM7BOX: t('qs_productlist.IXGDM7BOX.detail'),
      IXGDM7HID: t('qs_productlist.IXGDM7HID.detail'),
      IXGDM7HIDA: t('qs_productlist.IXGDM7HIDA.detail'),
      IXGFMK2C7: t('qs_productlist.IXGFMK2C7.detail'),
      IXGMK: t('qs_productlist.IXGMK.detail'),
      IXGWGW: t('qs_productlist.IXGWGW.detail'),
      IXGWTGW: t('qs_productlist.IXGWTGW.detail'),
      IXGWGW10: t('qs_productlist.IXGWGW10.detail'),
      IXGWGW100: t('qs_productlist.IXGWGW100.detail'),
      IXGWGW150: t('qs_productlist.IXGWGW150.detail'),
      IXGWGW200: t('qs_productlist.IXGWGW200.detail'),
      IXGWGW25: t('qs_productlist.IXGWGW25.detail'),
      IXGWGW250: t('qs_productlist.IXGWGW250.detail'),
      IXGWGW300: t('qs_productlist.IXGWGW300.detail'),
      IXGWGW350: t('qs_productlist.IXGWGW350.detail'),
      IXGWGW400: t('qs_productlist.IXGWGW400.detail'),
      IXGWGW450: t('qs_productlist.IXGWGW450.detail'),
      IXGWGW50: t('qs_productlist.IXGWGW50.detail'),
      IXGWGW500: t('qs_productlist.IXGWGW500.detail'),
      IXGWGW75: t('qs_productlist.IXGWGW75.detail'),
      IXGWLC: t('qs_productlist.IXGWLC.detail'),
      IXGWLCRY20: t('qs_productlist.IXGWLCRY20.detail'),
      IXMB: t('qs_productlist.IXMB.detail'),
      IXMV: t('qs_productlist.IXMV.detail'),
      IXMV7B: t('qs_productlist.IXMV7B.detail'),
      IXMV7HB: t('qs_productlist.IXMV7HB.detail'),
      IXMV7HBL: t('qs_productlist.IXMV7HBL.detail'),
      IXMV7HW: t('qs_productlist.IXMV7HW.detail'),
      IXMV7HWJP: t('qs_productlist.IXMV7HWJP.detail'),
      IXMV7W: t('qs_productlist.IXMV7W.detail'),
      IXNVP: t('qs_productlist.IXNVP.detail'),
      IXNVP2: t('qs_productlist.IXNVP2.detail'),
      IXPA: t('qs_productlist.IXPA.detail'),
      IXRSB: t('qs_productlist.IXRSB.detail'),
      IXRSW: t('qs_productlist.IXRSW.detail'),
      IXSDH: t('qs_productlist.IXSDH.detail'),
      IXSDHP: t('qs_productlist.IXSDHP.detail'),
      IXSHBDV: t('qs_productlist.IXSHBDV.detail'),
      IXSOFT: t('qs_productlist.IXSOFT.detail'),
      IXSS: t('qs_productlist.IXSS.detail'),
      IXSS2G: t('qs_productlist.IXSS2G.detail'),
      IXSS2RA: t('qs_productlist.IXSS2RA.detail'),
      IXSSA: t('qs_productlist.IXSSA.detail'),
      IXSSA2RA: t('qs_productlist.IXSSA2RA.detail'),
      IXSSARA: t('qs_productlist.IXSSARA.detail'),
      IXSSRA: t('qs_productlist.IXSSRA.detail'),
      IXWHWCBP: t('qs_productlist.IXWHWCBP.detail'),
      IXWMA: t('qs_productlist.IXWMA.detail'),
      IXWMASOFT: t('qs_productlist.IXWMASOFT.detail'),
      IXWMASOFT5: t('qs_productlist.IXWMASOFT5.detail'),
      IXWMASOFT10: t('qs_productlist.IXWMASOFT10.detail'),
      IXWMAASOFT: t('qs_productlist.IXWMAASOFT.detail'),
      IXWMAASOFT5: t('qs_productlist.IXWMAASOFT5.detail'),
      IXWMAASOFT10: t('qs_productlist.IXWMAASOFT10.detail'),
      IXWPBXA: t('qs_productlist.IXWPBXA.detail'),
      JF2HD: t('qs_productlist.JF2HD.detail'),
      JF2MED: t('qs_productlist.JF2MED.detail'),
      JF2SD: t('qs_productlist.JF2SD.detail'),
      JFD: t('qs_productlist.JFD.detail'),
      JFDA: t('qs_productlist.JFDA.detail'),
      JFDV: t('qs_productlist.JFDV.detail'),
      JFDVF: t('qs_productlist.JFDVF.detail'),
      JFDVFHID: t('qs_productlist.JFDVFHID.detail'),
      JFS2AED: t('qs_productlist.JFS2AED.detail'),
      JFS2AEDF: t('qs_productlist.JFS2AEDF.detail'),
      JFS2AEDV: t('qs_productlist.JFS2AEDV.detail'),
      JK1HD: t('qs_productlist.JK1HD.detail'),
      JKDA: t('qs_productlist.JKDA.detail'),
      JKDV: t('qs_productlist.JKDV.detail'),
      JKDVF: t('qs_productlist.JKDVF.detail'),
      JKMB: t('qs_productlist.JKMB.detail'),
      JKS1AED: t('qs_productlist.JKS1AED.detail'),
      JKS1AEDF: t('qs_productlist.JKS1AEDF.detail'),
      JKS1AEDV: t('qs_productlist.JKS1AEDV.detail'),
      JO1FD: t('qs_productlist.JO1FD.detail'),
      JODV: t('qs_productlist.JODV.detail'),
      JOS1AW: t('qs_productlist.JOS1AW.detail'),
      JOS1FW: t('qs_productlist.JOS1FW.detail'),
      JOS1VW: t('qs_productlist.JOS1VW.detail'),
      JOW2D: t('qs_productlist.JOW2D.detail'),
      JP4HD: t('qs_productlist.JP4HD.detail'),
      JP4MED: t('qs_productlist.JP4MED.detail'),
      JP8Z: t('qs_productlist.JP8Z.detail'),
      JPDA: t('qs_productlist.JPDA.detail'),
      JPDV: t('qs_productlist.JPDV.detail'),
      JPDVF: t('qs_productlist.JPDVF.detail'),
      JPDVFPR: t('qs_productlist.JPDVFPR.detail'),
      JPS4AED: t('qs_productlist.JPS4AED.detail'),
      JPS4AEDF: t('qs_productlist.JPS4AEDF.detail'),
      JPS4AEDV: t('qs_productlist.JPS4AEDV.detail'),
      JPWBA: t('qs_productlist.JPWBA.detail'),
      JVS1V: t('qs_productlist.JVS1V.detail'),
      JVS1F: t('qs_productlist.JVS1F.detail'),
      JV1FD: t('qs_productlist.JV1FD.detail'),
      KADGR: t('qs_productlist.KADGR.detail'),
      KB3HRD: t('qs_productlist.KB3HRD.detail'),
      KB3MRD: t('qs_productlist.KB3MRD.detail'),
      KB3SD: t('qs_productlist.KB3SD.detail'),
      KBDAR: t('qs_productlist.KBDAR.detail'),
      KBDARM: t('qs_productlist.KBDARM.detail'),
      KMB45: t('qs_productlist.KMB45.detail'),
      LEA: t('qs_productlist.LEA.detail'),
      LEAN: t('qs_productlist.LEAN.detail'),
      LEB: t('qs_productlist.LEB.detail'),
      LEBN: t('qs_productlist.LEBN.detail'),
      LED: t('qs_productlist.LED.detail'),
      LEDA: t('qs_productlist.LEDA.detail'),
      LEF1C: t('qs_productlist.LEF1C.detail'),
      LEF10: t('qs_productlist.LEF10.detail'),
      LEF10C: t('qs_productlist.LEF10C.detail'),
      LEF10S: t('qs_productlist.LEF10S.detail'),
      LEF3: t('qs_productlist.LEF3.detail'),
      LEF3C: t('qs_productlist.LEF3C.detail'),
      LEF3L: t('qs_productlist.LEF3L.detail'),
      LEF5: t('qs_productlist.LEF5.detail'),
      LEF5C: t('qs_productlist.LEF5C.detail'),
      LEM1: t('qs_productlist.LEM1.detail'),
      LEM1DL: t('qs_productlist.LEM1DL.detail'),
      LEM1DLC: t('qs_productlist.LEM1DLC.detail'),
      LEM1DLS: t('qs_productlist.LEM1DLS.detail'),
      LESS_A: t('qs_productlist.LESS_A.detail'),
      LESS1G: t('qs_productlist.LESS1G.detail'),
      LESSR: t('qs_productlist.LESSR.detail'),
      LSNVP_C: t('qs_productlist.LSNVP_C.detail'),
      MCWS_A: t('qs_productlist.MCWS_A.detail'),
      MCWS_B: t('qs_productlist.MCWS_B.detail'),
      MD120M: t('qs_productlist.MD120M.detail'),
      MD35M: t('qs_productlist.MD35M.detail'),
      MD60M: t('qs_productlist.MD60M.detail'),
      MH15W: t('qs_productlist.MH15W.detail'),
      NFCCARD: t('qs_productlist.NFCCARD.detail'),
      NFCFOB: t('qs_productlist.NFCFOB.detail'),
      NH1SA_A: t('qs_productlist.NH1SA_A.detail'),
      NH2SA_A: t('qs_productlist.NH2SA_A.detail'),
      NHR30K: t('qs_productlist.NHR30K.detail'),
      NHR3TS: t('qs_productlist.NHR3TS.detail'),
      NHR4A_A: t('qs_productlist.NHR4A_A.detail'),
      NHR7A: t('qs_productlist.NHR7A.detail'),
      NHR8C: t('qs_productlist.NHR8C.detail'),
      NHRSP: t('qs_productlist.NHRSP.detail'),
      NHX30G: t('qs_productlist.NHX30G.detail'),
      NHX50M: t('qs_productlist.NHX50M.detail'),
      NHX80X: t('qs_productlist.NHX80X.detail'),
      NP25V: t('qs_productlist.NP25V.detail'),
      PC_Master_Station: t('qs_productlist.PC_Master_Station.detail'),
      PS1208UL: t('qs_productlist.PS1208UL.detail'),
      PS1225UL: t('qs_productlist.PS1225UL.detail'),
      PS1820UL: t('qs_productlist.PS1820UL.detail'),
      PS2420UL: t('qs_productlist.PS2420UL.detail'),
      PSPOE: t('qs_productlist.PSPOE.detail'),
      PT1210NA: t('qs_productlist.PT1210NA.detail'),
      PT1211C: t('qs_productlist.PT1211C.detail'),
      PT1211CA: t('qs_productlist.PT1211CA.detail'),
      RY1824L: t('qs_productlist.RY1824L.detail'),
      RY3DL: t('qs_productlist.RY3DL.detail'),
      RYAC_A: t('qs_productlist.RYAC_A.detail'),
      RYES: t('qs_productlist.RYES.detail'),
      RYIP44: t('qs_productlist.RYIP44.detail'),
      RYPA: t('qs_productlist.RYPA.detail'),
      SBX1G: t('qs_productlist.SBX1G.detail'),
      SBX2G_A: t('qs_productlist.SBX2G_A.detail'),
      SBXDVF: t('qs_productlist.SBXDVF.detail'),
      SBXDVFP: t('qs_productlist.SBXDVFP.detail'),
      SBXGTDMB: t('qs_productlist.SBXGTDMB.detail'),
      SBXIDVF: t('qs_productlist.SBXIDVF.detail'),
      SBXIDVFRA: t('qs_productlist.SBXIDVFRA.detail'),
      SBXISDVFP: t('qs_productlist.SBXISDVFP.detail'),
      SBXIXDV30: t('qs_productlist.SBXIXDV30.detail'),
      SBXIXGDM7: t('qs_productlist.SBXIXGDM7.detail'),
      SBXNVP_A: t('qs_productlist.SBXNVP_A.detail'),
      SKK620C: t('qs_productlist.SKK620C.detail'),
      SP2570N: t('qs_productlist.SP2570N.detail'),
      TW20B: t('qs_productlist.TW20B.detail'),
      TW20B_A: t('qs_productlist.TW20B_A.detail'),
      TW20K: t('qs_productlist.TW20K.detail'),
      TW20K_A: t('qs_productlist.TW20K_A.detail'),
      TW20R: t('qs_productlist.TW20R.detail'),
      TW20R_A: t('qs_productlist.TW20R_A.detail'),
      TW20W: t('qs_productlist.TW20W.detail'),
      TW20W_A: t('qs_productlist.TW20W_A.detail'),
      TW20Y: t('qs_productlist.TW20Y.detail'),
      TW20Y_A: t('qs_productlist.TW20Y_A.detail'),
      TW23B: t('qs_productlist.TW23B.detail'),
      TW23B_A: t('qs_productlist.TW23B_A.detail'),
      TW23K: t('qs_productlist.TW23K.detail'),
      TW23K_A: t('qs_productlist.TW23K_A.detail'),
      TW23R: t('qs_productlist.TW23R.detail'),
      TW23R_A: t('qs_productlist.TW23R_A.detail'),
      TW23W: t('qs_productlist.TW23W.detail'),
      TW23W_A: t('qs_productlist.TW23W_A.detail'),
      TW23Y: t('qs_productlist.TW23Y.detail'),
      TW23Y_A: t('qs_productlist.TW23Y_A.detail'),
      TWARMCB: t('qs_productlist.TWARMCB.detail'),
      TWARMCK: t('qs_productlist.TWARMCK.detail'),
      TWARMCR: t('qs_productlist.TWARMCR.detail'),
      TWARMCW: t('qs_productlist.TWARMCW.detail'),
      TWARMCY: t('qs_productlist.TWARMCY.detail'),
      TWASB: t('qs_productlist.TWASB.detail'),
      TWASK: t('qs_productlist.TWASK.detail'),
      TWASR: t('qs_productlist.TWASR.detail'),
      TWASW: t('qs_productlist.TWASW.detail'),
      TWASY: t('qs_productlist.TWASY.detail'),
      TWEMB: t('qs_productlist.TWEMB.detail'),
      TWEMK: t('qs_productlist.TWEMK.detail'),
      TWEMR: t('qs_productlist.TWEMR.detail'),
      TWEMW: t('qs_productlist.TWEMW.detail'),
      TWEMY: t('qs_productlist.TWEMY.detail'),
      TWLCB: t('qs_productlist.TWLCB.detail'),
      TWLCK: t('qs_productlist.TWLCK.detail'),
      TWLCR: t('qs_productlist.TWLCR.detail'),
      TWLCW: t('qs_productlist.TWLCW.detail'),
      TWLCY: t('qs_productlist.TWLCY.detail'),
      TWMKL: t('qs_productlist.TWMKL.detail'),
      TWSA: t('qs_productlist.TWSA.detail'),
      TWSE: t('qs_productlist.TWSE.detail'),
      TWTA: t('qs_productlist.TWTA.detail'),
      TWTAARM: t('qs_productlist.TWTAARM.detail'),
      TWTE: t('qs_productlist.TWTE.detail'),
      TWTEARM: t('qs_productlist.TWTEARM.detail'),
      TWTPB: t('qs_productlist.TWTPB.detail'),
      TWTPK: t('qs_productlist.TWTPK.detail'),
      TWTPR: t('qs_productlist.TWTPR.detail'),
      TWTPW: t('qs_productlist.TWTPW.detail'),
      TWTPY: t('qs_productlist.TWTPY.detail'),
      WBCA: t('qs_productlist.WBCA.detail'),
      WBCE: t('qs_productlist.WBCE.detail'),
      WBHA: t('qs_productlist.WBHA.detail'),
      WBHE: t('qs_productlist.WBHE.detail'),
      WDIN11: t('qs_productlist.WDIN11.detail'),
      IXWMAA: t('qs_productlist.IXWMAA.detail'),
      SSB3: t('qs_productlist.SSB3.detail'),
      IXDVF10KP: t('qs_productlist.IXDVF10KP.detail')
    }),
    [t]
  ) as StringToStringMap;
};

/**
 * Provides localized strings for various Quick Specification notes and links.
 *
 * @returns {Object} An object containing the following properties:
 * - `Component1`: Localized string for Component1.
 * - `Component2`: Localized string for Component2.
 * - `Component3`: Localized string for Component3.
 * - `Component4`: Localized string for Component4.
 * - `Component5`: Localized string for Component5.
 * - `Component6`: Localized string for Component6.
 * - `Component7`: Localized string for Component7.
 * - `Link`: An object containing various series links with their text and URL as localized strings.
 *    - `IX Series`: Object with `text` and `URL` properties for IX Series.
 *    - `IXG Series`: Object with `text` and `URL` properties for IXG Series.
 *    - `IX | IXG Series`: Object with `text` and `URL` properties for IX | IXG Series.
 *    - `AC Series`: Object with `text` and `URL` properties for AC Series.
 *    - `JP Series`: Object with `text` and `URL` properties for JP Series.
 *    - `GT Series`: Object with `text` and `URL` properties for GT Series.
 *    - `JO Series`: Object with `text` and `URL` properties for JO Series.
 *    - `JV Series`: Object with `text` and `URL` properties for JV Series.
 *    - `AX Series`: Object with `text` and `URL` properties for AX Series.
 *    - `KB Series`: Object with `text` and `URL` properties for KB Series.
 *    - `NHX Series`: Object with `text` and `URL` properties for NHX Series.
 *    - `LEF Series`: Object with `text` and `URL` properties for LEF Series.
 */
export const useQuikSpecNotes = (): object => {
  const { t } = useTranslation();
  return React.useMemo(
    () => ({
      Component1: t('qs_note.Note.Component1'),
      Component2: t('qs_note.Note.Component2'),
      Component3: t('qs_note.Note.Component3'),
      Component4: t('qs_note.Note.Component4'),
      Component5: t('qs_note.Note.Component5'),
      Component6: t('qs_note.Note.Component6'),
      Component7: t('qs_note.Note.Component7'),
      Link: {
        'IX Series': {
          text: t('qs_note.Note.Link.IX_Series.text'),
          URL: 'https://www.aiphone.com/products/ix-series'
        },
        'IXG Series': {
          text: t('qs_note.Note.Link.IXG_Series.text'),
          URL: 'https://www.aiphone.com/home/products/ixg-series'
        },
        'IX | IXG Series': {
          text: t('qs_note.Note.Link.IX_|_IXG_Series.text'),
          URL: 'https://www.aiphone.com/products/ix-ixg-series'
        },
        'AC Series': {
          text: t('qs_note.Note.Link.AC_Series.text'),
          URL: 'https://www.aiphone.com/products/ac-series'
        },
        'JP Series': {
          text: t('qs_note.Note.Link.JP_Series.text'),
          URL: 'https://www.aiphone.com/products/jp-series'
        },
        'GT Series': {
          text: t('qs_note.Note.Link.GT_Series.text'),
          URL: 'https://www.aiphone.com/products/gt-series-2'
        },
        'JO Series': {
          text: t('qs_note.Note.Link.JO_Series.text'),
          URL: 'https://www.aiphone.com/products/jo-series'
        },
        'JV Series': {
          text: t('qs_note.Note.Link.JV_Series.text'),
          URL: 'https://www.aiphone.com/products/jv-series'
        },
        'AX Series': {
          text: t('qs_note.Note.Link.AX_Series.text'),
          URL: 'https://www.aiphone.com/products/ax-series'
        },
        'KB Series': {
          text: t('qs_note.Note.Link.KB_Series.text'),
          URL: 'https://www.aiphone.com/products/kb-series'
        },
        'NHX Series': {
          text: t('qs_note.Note.Link.NHX_Series.text'),
          URL: 'https://www.aiphone.com/products/nhx-series'
        },
        'LEF Series': {
          text: t('qs_note.Note.Link.LEF_Series.text'),
          URL: 'https://www.aiphone.com/products/lef-series'
        }
      }
    }),
    [t]
  );
};
