import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  FormControl,
  TextField,
  Button,
  CircularProgress,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Box,
  InputAdornment
} from '@mui/material';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CancelIcon from '@mui/icons-material/Cancel';
import { useInviteSiteUserMutation, useGetAssignableRolesQuery } from 'services/aiphoneCloud';
import { ContextType } from 'store/slices/usersSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';

interface IRole {
  publicId: string;
  roleName: string;
  description?: string;
}

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onInviteSuccess: () => void;
  userEmailList: string[];
}

interface FormValues {
  emailAddress: string;
  userRole: string;
}

const InviteAiphoneUserDialog: React.FC<Props> = ({ isOpen, setIsOpen, onInviteSuccess, userEmailList }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [roleName, setRoleName] = useState<string | null>(null);
  const [rolesData, setRolesData] = useState<IRole[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const user = useSelector((state: RootState) => state.users.currentUser);
  const publicId = useSelector((state: RootState) => state.site.siteInfo?.publicId);
  const currentSite = useSelector((state: RootState) => state.site.siteInfo);
  const { data } = useGetAssignableRolesQuery({
    contextPublicId: publicId,
    contextType: ContextType.SITE
  });
  // Update rolesData state after data is fetched
  useEffect(() => {
    if (data) {
      const updatedRolesData = data.map((role: IRole) => ({
        publicId: role.publicId,
        roleName: role.roleName,
        description: getRoleDescription(role.roleName)
      }));
      setRolesData(updatedRolesData);
    }
  }, [data]);

  // Function to get role description based on role name
  const getRoleDescription = (roleName: string) => {
    switch (roleName) {
      case 'Admin':
        return t('Aiphone_Admin_Permission');
      case 'Manager':
        return t('Aiphone_Manager_Permission');
      case 'Member':
        return t('Aiphone_Member_Permission');
      default:
        return '';
    }
  };
  // Reset success and error messages when the dialog opens
  useEffect(() => {
    if (isOpen) {
      setSuccessMessage(null);
      setErrorMessage(null);
    }
  }, [isOpen]);

  const validationSchema = Yup.object({
    emailAddress: Yup.string()
      .required(t('Email_is_required'))
      .matches(/^[^\s@]+$/, t('Invalid_Aiphone_Email'))
      .test('email-exists', t('User_Already_Exists'), (value) => {
        const email = `${value.toLowerCase()}@aiphone.com`;
        return !userEmailList.includes(email);
      }),
    userRole: Yup.string().required(t('User_role_is_required'))
  });

  const [inviteUser] = useInviteSiteUserMutation();

  const handleInvite = async (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      const email = `${values.emailAddress.toLowerCase()}@aiphone.com`;
      const inviteUserPayload = {
        sitePublicId: publicId,
        rolePublicId: values.userRole,
        roleName: roleName,
        email,
        invitedByFirstName: user?.firstName,
        invitedByLastName: user?.lastName,
        invitedSiteName: currentSite?.siteName,
        invitedSiteAddress: currentSite?.siteAddress,
        invitedSiteAddress2: currentSite?.siteAddress2,
        invitedSiteCity: currentSite?.siteCity,
        invitedSiteZip: currentSite?.siteZip,
        languageId: 2,
        countryId: 2
      };

      await inviteUser(inviteUserPayload).unwrap();
      setSuccessMessage(t('User_invited_successfully'));
      onInviteSuccess();

      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    } catch (error) {
      setErrorMessage(t('Failed_to_invite_aiphone_user'));
      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const handleCancelClick = () => {
    setIsOpen(false);
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  return (
    <Dialog onClose={handleCancelClick} open={isOpen} maxWidth="sm" fullWidth>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>{t('Invite_Aiphone_User')}</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={handleCancelClick}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Formik
        initialValues={{ emailAddress: '', userRole: '' }}
        validationSchema={validationSchema}
        onSubmit={handleInvite}
      >
        {({ errors, touched, handleBlur, handleChange, setFieldValue, values, isSubmitting }) => (
          <Form noValidate>
            <DialogContent>
              <Box sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <TextField
                        name="emailAddress"
                        label="Aiphone User Email"
                        value={values.emailAddress}
                        onChange={(e) => {
                          const username = e.target.value.replace(/@iphone\.com$/, '');
                          setFieldValue('emailAddress', username);
                        }}
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">@aiphone.com</InputAdornment>
                        }}
                        helperText={
                          touched.emailAddress && errors.emailAddress ? errors.emailAddress : t('Aiphone_Email_Invite')
                        }
                        error={touched.emailAddress && Boolean(errors.emailAddress)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={touched.userRole && !!errors.userRole}
                      disabled={!rolesData || rolesData.length === 0}
                      sx={styles.inputField}
                    >
                      <InputLabel id="user-role-select-input-label">{t('Role')}</InputLabel>
                      <Field
                        as={Select}
                        name="userRole"
                        label={t('Role')}
                        fullWidth
                        variant="outlined"
                        size="small"
                        onBlur={(e: any) => {
                          handleBlur(e);
                          setErrorMessage(null);
                        }}
                        onChange={(e: any) => {
                          const selectedRole = rolesData?.find((role: IRole) => role.publicId === e.target.value);
                          setRoleName(selectedRole?.roleName ?? '');
                          handleChange(e);
                          setErrorMessage(null);
                        }}
                        labelId="user-role-select-input-label"
                      >
                        {Array.isArray(rolesData) && rolesData.length > 0 ? (
                          rolesData.map((role: IRole) => (
                            <MenuItem key={role.publicId} value={role.publicId}>
                              <div>
                                {`Aiphone ${role.roleName}`}
                                {role.description && (
                                  <Typography variant="body2" color="textSecondary">
                                    {role.description}
                                  </Typography>
                                )}
                              </div>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" disabled>
                            {t('No_roles_available')}
                          </MenuItem>
                        )}
                      </Field>
                      <FormHelperText>{touched.userRole && errors.userRole}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              {errorMessage && (
                <Typography variant="body2" color="error" align="center">
                  {errorMessage}
                </Typography>
              )}
              {successMessage && (
                <Typography variant="body2" color="success" align="center">
                  {successMessage}
                </Typography>
              )}
              {!errorMessage && (
                <Typography variant="body2" color="textSecondary" align="center" sx={styles.fonts}>
                  {t('Invite_Delay_Message')}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelClick}>{t('Cancel')}</Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || loading || !values.emailAddress || !values.userRole || !!errors.emailAddress}
                startIcon={loading && <CircularProgress size={20} />}
              >
                {t('Send_Invite')}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const styles = {
  fonts: {
    fontSize: '0.75rem',
    color: '#B0B0B0'
  },
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'gray',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray'
      },
      '&:hover fieldset': {
        '& .enabled': {
          borderColor: '#003366'
        }
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  }
};

export default InviteAiphoneUserDialog;
