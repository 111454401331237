import { createSlice } from '@reduxjs/toolkit';
import { UUID } from 'crypto';
import { aiphoneCloudApi } from 'services/aiphoneCloud';

export interface IUnit {
  accessCode: string;
  appPublicIds: (UUID | string)[];
  buildingPublicId: UUID;
  unitSequentialNumber: number;
  createdOn: string;
  devicePublicIds: (UUID | string)[];
  lastUpdatedOn: string;
  publicId: UUID;
  unitAwsRoomAuthCode: string;
  unitEmailAddress: string;
  unitGuardUnitId: string;
  unitLiftControlAdaptor: string;
  unitName: string;
  unitNumber: string;
  unitType: number;
}

export interface IUnitTypes {
  [key: string]: string[];
}

export interface IUnitState {
  UnitList: IUnitList;
  UnitListByType: IUnitTypes;
  SelectedUnit: string;
  Loading: boolean;
}

export interface IUnitList {
  [key: string]: IUnit;
}

export interface IUnitInfo {
  devicePublicIds?: UUID[] | string[];
  unit: IUnit;
}

const initialState: IUnitState = {
  UnitList: {},
  UnitListByType: {},
  SelectedUnit: '',
  Loading: false
};

export const unitTypeMapping: { [key: number]: string } = {
  1: 'Guard',
  2: 'Entrance',
  3: 'InsideArea',
  4: 'Residential',
  5: 'Commercial',
  6: 'OutsideArea'
};

const getUpdatedUnitListByType = (unitList: IUnitList) => {
  const unitListByType: IUnitTypes = {
    Guard: [],
    Entrance: [],
    InsideArea: [],
    Residential: [],
    Commercial: [],
    OutsideArea: []
  };

  Object.values(unitList).forEach((unit) => {
    const unitType = unitTypeMapping[unit.unitType];
    unitListByType[unitType].push(unit.publicId);
  });

  return unitListByType;
};

const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    setSelectedUnitId: (state, action) => {
      state.SelectedUnit = action.payload;
    },
    removeUnit: (state, action) => {
      delete state.UnitList[action.payload];
      state.UnitListByType = getUpdatedUnitListByType(state.UnitList);
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(aiphoneCloudApi.endpoints.getUnitListWithBuildingPublicId.matchFulfilled, (state, { payload }) => {
        state.UnitList = {};
        state.UnitList = payload.unitList;
        state.UnitListByType = getUpdatedUnitListByType(payload.unitList);
      })
      .addMatcher(aiphoneCloudApi.endpoints.getUnitListWithSitePublicId.matchFulfilled, (state, { payload }) => {
        state.UnitList = {};
        state.UnitList = payload.unitList;
        state.UnitListByType = getUpdatedUnitListByType(payload.unitList);
      })
      .addMatcher(aiphoneCloudApi.endpoints.getBuildings.matchFulfilled, (state, { payload }) => {
        if (payload && payload.unitPublicIds > 0) {
          state.UnitList = payload.unitPublicIds;
        }
      });
  }
});

export const { setSelectedUnitId, removeUnit } = unitsSlice.actions;

export const unitsReducer = unitsSlice.reducer;
