/* eslint-disable unused-imports/no-unused-vars */
/**
 * Sync functionality, Firmware functionality for the site dashboard.
 */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setLastSyncedOn } from 'store/slices/devicesSlice';
import { getSite } from 'store/slices/siteSlice';
import { Alert, Box, Grid, Paper } from '@mui/material';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { Status } from 'shared/utils/Status';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import {
  useGetSiteWithPublicIdQuery,
  useHandleGatewayCommandMutation,
  useUpdateSyncedDateMutation
} from 'services/aiphoneCloud';
import { getAllSoundsBySiteId } from 'shared/api/Aws/RemoteManagementApi';
import { IRingtone } from 'features/RemoteManagement/DeviceDashboard/callSettings/outboundCallSetting/settings/RingBackTone';
import { useTranslation } from 'react-i18next';
import { getGWErrorCode } from 'shared/rmGateway/gwErrorHandler';
import LoginDialog from './Dialogs/LoginDialog';
import SyncDialog from './Dialogs/SyncDialog';
import GWFirmwareDialog from './Dialogs/GWFirmwareDialog';
import ToolbarComponent from './Toolbar/ToolbarComponent';
import { RowElem, SiteGrid } from './Grid/SiteGrid';
import { computeCredentials, computeGwCredential, latestFirmwareUrl, parseS3Url } from './Utils/SiteUtil';
import IXGAppConfigurationCard from './CardComponents/IXGAppConfigurationCard';
import GatewayConfigurationCard from './CardComponents/GatewayConfigurationCard';
import GatewayFirmwareCard from './CardComponents/GatewayFirmwareCard';
import GatewayStatusCard from './CardComponents/GatewayStatusCard';
import { useSiteContext } from './SiteContext/SiteContext';
import { useParams } from 'react-router-dom';

interface ISyncS3Location {
  siteUUID: string;
  gatewayUUID: string;
  deviceUUID: string;
  deviceType: string;
  configFilePath: string;
  currentUser: string | undefined;
}

interface IGatewayInfo {
  awsPropertyId: string;
  gwMacAddress: string;
  gwId: string;
  gwPassword: string;
  gwIpAddress: string;
}

const Site = () => {
  const {
    rows,
    setRows,
    gwOnlineStatus,
    hasEditPermission,
    isGWRegistered,
    setIsGatewayRegistered,
    setIsGatewayUnregistered,
    setLatestFirmwareList,
    errorMessage,
    setErrorMessage,
    successMessage,
    showAlert,
    setShowAlert,
    showIXGSync,
    setShowIXGSync,
    setIsLoginDialogOpen,
    setIsSyncDialogOpen,
    buildDeviceRow
  } = useSiteContext();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateSyncedDate] = useUpdateSyncedDateMutation();
  const site = useSelector(getSite);
  const gateway = useSelector(
    (state: RootState) => state.devices.DeviceList[site?.siteInfo?.registeredGatewayPublicId]
  );
  const { DeviceList: devices } = useSelector((state: RootState) => state.devices);
  const params = useParams();
  const siteId = params['id'] || '';
  useGetSiteWithPublicIdQuery(siteId, { skip: !siteId });
  const users = useSelector((state: RootState) => state.users);
  const currentUser = users.currentUser;

  //Sync sound files variables
  const [ringtoneList, setRingtoneList] = useState<IRingtone[]>([]);

  const handleClose = () => {
    setIsLoginDialogOpen(false);
    setIsSyncDialogOpen(false);
  };

  const [handleGatewayCommand, { isLoading: isGwProcessingCommand }] = useHandleGatewayCommandMutation();

  const isGatewayFirstSync = gateway?.lastSyncedOn === null;

  useEffect(() => {
    (async () => {
      const checkLatestFirmware = async () => {
        const firmwareVersionCheck = await fetch(latestFirmwareUrl);
        const latestVersion = await firmwareVersionCheck.json();
        setLatestFirmwareList(latestVersion);
      };
      await checkLatestFirmware();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const deviceRows = buildDeviceRow(Object.values(devices));
      const sortedRows = deviceRows.sort((a, b) => {
        if (a.ModelNumber === 'IXGW-GW' && b.ModelNumber !== 'IXGW-GW') {
          return -1;
        } else if (a.ModelNumber !== 'IXGW-GW' && b.ModelNumber === 'IXGW-GW') {
          return 1;
        } else {
          return 0;
        }
      });

      setRows(sortedRows);

      if (Object.keys(devices).length === 0) {
        // Set the gateway registration state to false because there are no devices.
        setIsGatewayRegistered(false);
      } else if (Object.keys(devices).length > 0) {
        setIsGatewayRegistered(gateway !== undefined);
        setIsGatewayUnregistered(true);
      }
    })();
  }, [devices, gateway, site]);

  useEffect(() => {
    /** Retrieving all audio files to generate the link for sending to the gateway. */
    if (site.siteInfo?.publicId && users.currentUser?.publicId) {
      const getAllSoundsPayload = {
        siteId: site.siteInfo.publicId,
        userId: users.currentUser?.publicId
      };
      (async () => {
        const allSounds = (await getAllSoundsBySiteId(getAllSoundsPayload)) as IRingtone[];
        if (allSounds?.length > 0) {
          setRingtoneList(allSounds);
        }
      })();
    }
  }, []);

  /****************************************   SYNC   ****************************************/
  /**
   *
   * @note This function needs to be refactored, there is a lot of repeated code as well as some logic that isn't fully understood.
   *
   */
  const handleConfigFileUpload = async (device: RowElem) => {
    /** throw error if the device config file is missing */
    if (device.ConfigFileUrl === '-') {
      setErrorMessage(t('Config_file_is_Missing'));
      return;
    }
    const selectedDevice = devices[device.id];
    if (!selectedDevice) {
      setErrorMessage(t('Device_not_Found'));
      return;
    }
    if (!gateway) {
      setErrorMessage(t('Gateway_not_found'));
      return;
    }
    const { id, password } = computeCredentials(selectedDevice);
    const isDeviceFirstSync = selectedDevice.lastSyncedOn === null;
    const deviceType = device.ModelNumber;
    let ioTPayload, fetchPayload;
    //Todo: check with Derek for systemId and systemPassword
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;

    const gwCredential = computeGwCredential(gateway);

    const configFilePath = parseS3Url(device.ConfigFileUrl);

    if (!configFilePath) {
      setErrorMessage(t('Config_file_is_Missing'));
      return;
    }
    //prepare device information for config file
    const s3Location: ISyncS3Location = {
      siteUUID: site?.siteInfo?.publicId,
      gatewayUUID: gateway?.publicId,
      deviceUUID: selectedDevice.publicId,
      deviceType: deviceType || '',
      configFilePath: device.ConfigFileUrl,
      currentUser: currentUser?.publicId
    };

    const gatewayInfo: IGatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: gwCredential.gwId,
      gwPassword: gwCredential.gwPassword,
      gwIpAddress: gateway?.networkSettings?.ipV4Address
    };

    try {
      let deviceInfo = {
        deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
        deviceMacAddress: device.MacAddress,
        deviceStationNumber: device.StationNumber,
        deviceId: id,
        devicePassword: password,
        deviceConfigFileUrl: device.ConfigFileUrl,
        deviceType,
        deviceFileName: `config-${device.id}.txt`,
        deviceS3Url: configFilePath
      };
      if (!isGwProcessingCommand && gwOnlineStatus === Status.Online) {
        ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
        if (ioTPayload === 'Missing information') {
          throw new Error('Missing information');
        }
        if (ioTPayload && typeof ioTPayload === 'object' && 'payload' in ioTPayload) {
          (ioTPayload as any)['configFileLocation'] = s3Location;
        }

        await handleGatewayCommand(ioTPayload).unwrap();

        // NOTE: changed on Jan 10 due to endpoint timeout issue, initial sync to wait 3 minutes, else 60 seconds
        const waitTime = isDeviceFirstSync ? 180000 : 60000;
        await new Promise((resolve) => setTimeout(resolve, waitTime));

        fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.SYNC, gatewayInfo, deviceInfo, null);

        const fetchResponse = await handleGatewayCommand(fetchPayload)
          .unwrap()
          .catch((error) => {
            throw new Error(error);
          });

        if (deviceType === 'IXGW-GW' || deviceType === 'IXGW-TGW') {
          if (fetchResponse?.statusCode.includes('200')) {
            const params = {
              device: {
                publicId: selectedDevice.publicId
              }
            };
            updateSyncedDate(params);
            dispatch(setLastSyncedOn(params.device.publicId));
            return Status.Synced;
          } else if (fetchResponse?.statusCode.includes('402')) {
            // if we get 402, its possible something happened when we synced the device, and we should be suspicious of isDevice being incorrect
            const memoId = gateway?.basicInfo?.memoId || 'admin';
            const memoPassword = gateway?.basicInfo?.memoPass || 'admin';
            const gatewayInfo = {
              awsPropertyId: site?.siteInfo?.awsPropertyId,
              gwMacAddress: gateway?.basicInfo?.macAddress,
              gwId: systemId ?? (isGatewayFirstSync ? memoId : gateway?.basicInfo?.adminId),
              gwPassword: systemPassword ?? (isGatewayFirstSync ? memoPassword : gateway?.basicInfo?.adminPass),
              gwIpAddress: gateway?.networkSettings?.ipV4Address || ''
            };
            const deviceMemoId = selectedDevice?.basicInfo.memoId || 'admin';
            const deviceMemoPass = selectedDevice?.basicInfo.memoPass || 'admin';
            deviceInfo = {
              deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
              deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
              deviceStationNumber: selectedDevice?.basicInfo.stationNumber,
              deviceType: deviceType,
              deviceId: systemId ?? (isDeviceFirstSync ? selectedDevice?.basicInfo.adminId : deviceMemoId),
              devicePassword:
                systemPassword ?? (isDeviceFirstSync ? selectedDevice?.basicInfo.adminPass : deviceMemoPass),
              deviceConfigFileUrl: device.ConfigFileUrl,
              deviceFileName: `config-${device.id}.txt`
            };

            const tryDefaultAdminIDandPW = fetchGatewayCommand(
              'sendCommand',
              gwCommand.SYNC,
              gatewayInfo,
              deviceInfo,
              null
            );
            let fetchResponse = await handleGatewayCommand(tryDefaultAdminIDandPW).unwrap();

            // if this is the device's first sync, wait 3 minutes then fetch the result, else wait 30 seconds
            const waitTime = isDeviceFirstSync ? 30000 : 180000; // opposite if first attempt, in case it's the opposite of what we think
            await new Promise((resolve) => setTimeout(resolve, waitTime));

            // fetch the result from the gateway
            const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
            fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

            if (fetchResponse?.statusCode.includes('206')) {
              fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
            } else if (fetchResponse?.statusCode.includes('402')) {
              // Gateway id and pw were wrong
              const gatewayInfo = {
                awsPropertyId: site?.siteInfo?.awsPropertyId,
                gwMacAddress: gateway?.basicInfo?.macAddress,
                gwId: systemId ?? (isGatewayFirstSync ? gateway?.basicInfo.adminId : 'admin'),
                gwPassword: systemPassword ?? (isGatewayFirstSync ? gateway?.basicInfo.adminPass : 'admin'),
                gwIpAddress: gateway?.networkSettings?.ipV4Address || ''
              };
              const tryDefaultAdminIDandPW = fetchGatewayCommand(
                'sendCommand',
                gwCommand.SYNC,
                gatewayInfo,
                deviceInfo,
                null
              );
              await handleGatewayCommand(tryDefaultAdminIDandPW).unwrap();

              // if this is the device's first sync, wait 3 minutes, else wait 30 seconds
              const waitTime = isDeviceFirstSync ? 30000 : 180000; // opposite if first attempt, in case it's the opposite of what we think
              await new Promise((resolve) => setTimeout(resolve, waitTime));

              // fetch the result from the gateway
              const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
              await handleGatewayCommand(fetchPayload).unwrap();
            }

            const statusCode = fetchResponse?.payload[0].statusCode.slice(0, 3);
            // It means the Device was synced but we missed it, maybe due to lambda timeout or page refresh by user. So we need to update the Device's lastSyncedOn date
            if (statusCode === '200') {
              const params = {
                device: {
                  publicId: selectedDevice?.publicId
                }
              };
              updateSyncedDate(params);
              dispatch(setLastSyncedOn(params.device.publicId));
              device.Status = Status.Synced;
              setRows(rows);
              return;
            } else {
              return Status.Error;
            }
          } else {
            const statusCode = fetchResponse?.payload[0].statusCode.slice(0, 3);

            // TODO: Need to handle the case for different error codes or messages
            setErrorMessage(t(getGWErrorCode({ message: statusCode })));
            return Status.Error;
          }
        } else {
          const statusCode = fetchResponse?.payload[0].statusCode.slice(0, 3);
          if (statusCode === '200') {
            const params = {
              device: {
                publicId: selectedDevice.publicId
              }
            };
            updateSyncedDate(params);
            dispatch(setLastSyncedOn(params.device.publicId));
            return Status.Synced;
          } else if (statusCode === '402') {
            // if we get 402, its possible something happened when we synced the device, and we should be suspicious of isDevice being incorrect
            const memoId = gateway?.basicInfo?.memoId || 'admin';
            const memoPassword = gateway?.basicInfo?.memoPass || 'admin';
            const gatewayInfo = {
              awsPropertyId: site?.siteInfo?.awsPropertyId,
              gwMacAddress: gateway?.basicInfo?.macAddress,
              gwId: systemId ?? (isGatewayFirstSync ? memoId : gateway?.basicInfo?.adminId),
              gwPassword: systemPassword ?? (isGatewayFirstSync ? memoPassword : gateway?.basicInfo?.adminPass),
              gwIpAddress: gateway?.networkSettings?.ipV4Address || ''
            };
            const deviceMemoId = selectedDevice?.basicInfo.memoId || 'admin';
            const deviceMemoPass = selectedDevice?.basicInfo.memoPass || 'admin';
            deviceInfo = {
              deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
              deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
              deviceStationNumber: selectedDevice?.basicInfo.stationNumber,
              deviceType: deviceType,
              deviceId: 'admin',
              devicePassword: 'admin',
              deviceConfigFileUrl: device.ConfigFileUrl,
              deviceFileName: `config-${device.id}.txt`
            };

            const tryDefaultAdminIDandPW = fetchGatewayCommand(
              'sendCommand',
              gwCommand.SYNC,
              gatewayInfo,
              deviceInfo,
              null
            );
            let fetchResponse = await handleGatewayCommand(tryDefaultAdminIDandPW).unwrap();

            // if this is the device's first sync, wait 3 minutes, else wait 30 seconds
            const waitTime = isDeviceFirstSync ? 30000 : 180000; // opposite if first attempt, in case it's the opposite of what we think
            await new Promise((resolve) => setTimeout(resolve, waitTime));

            // fetch the result from the gateway
            const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
            fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

            if (fetchResponse?.statusCode.includes('206')) {
              fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
            } else if (fetchResponse?.statusCode.includes('402')) {
              // Gateway id and pw were wrong
              const gatewayInfo = {
                awsPropertyId: site?.siteInfo?.awsPropertyId,
                gwMacAddress: gateway?.basicInfo?.macAddress,
                gwId: systemId ?? (isGatewayFirstSync ? gateway?.basicInfo.adminId : 'admin'),
                gwPassword: systemPassword ?? (isGatewayFirstSync ? gateway?.basicInfo.adminPass : 'admin'),
                gwIpAddress: gateway?.networkSettings?.ipV4Address || ''
              };
              const tryDefaultAdminIDandPW = fetchGatewayCommand(
                'sendCommand',
                gwCommand.SYNC,
                gatewayInfo,
                deviceInfo,
                null
              );
              await handleGatewayCommand(tryDefaultAdminIDandPW).unwrap();

              // if this is the device's first sync, wait 3 minutes, else wait 30 seconds
              const waitTime = isDeviceFirstSync ? 30000 : 180000; // opposite if first attempt, in case it's the opposite of what we think
              await new Promise((resolve) => setTimeout(resolve, waitTime));

              // fetch the result from the gateway
              const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
              await handleGatewayCommand(fetchPayload).unwrap();
            }

            const retryStatusCode = fetchResponse?.payload[0].statusCode.slice(0, 3);
            // It means the Device was synced but we missed it, maybe due to lambda timeout or page refresh by user. So we need to update the Device's lastSyncedOn date
            if (retryStatusCode === '200') {
              const params = {
                device: {
                  publicId: selectedDevice?.publicId
                }
              };
              updateSyncedDate(params);
              dispatch(setLastSyncedOn(params.device.publicId));
              device.Status = Status.Synced;
              setRows(rows);
              return;
            } else {
              return Status.Error;
            }
          } else {
            const statusCode = fetchResponse?.payload[0].statusCode;

            // TODO: Need to handle the case for different error codes or messages
            setErrorMessage(t(getGWErrorCode({ message: statusCode })));
            return Status.Error;
          }
        }
      }
    } catch (error) {
      // TODO: Need to handle the case for different error codes or messages
      setErrorMessage(t('Failed_to_sync_with_IXG_Cloud'));
      return Status.Error;
    }
  };

  return (
    <>
      <Paper elevation={3} sx={styles.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ p: 2 }}>
              <Box sx={styles.title}>{t('Gateway_Action_Center')}</Box>
              {!hasEditPermission && <Box sx={styles.viewPermission}>{t('View_only_Permission')}</Box>}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <GatewayStatusCard />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <GatewayFirmwareCard />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <GatewayConfigurationCard gateway={gateway} handleConfigFileUpload={handleConfigFileUpload} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <IXGAppConfigurationCard />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box sx={styles.title}>{t('Station_Manager')}</Box>
                  {!isGWRegistered && (
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    <Alert severity="warning" onClose={() => {}}>
                      {t('Gateway_not_registered_warning')}
                    </Alert>
                  )}
                  <ToolbarComponent gateway={gateway} handleConfigFileUpload={handleConfigFileUpload} />
                  <SiteGrid handleConfigFileUpload={handleConfigFileUpload} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <SyncDialog handleClose={handleClose} />
      <LoginDialog handleClose={handleClose} />
      <GWFirmwareDialog />
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={successMessage ?? ''}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={t('Success_Synced_Device')}
        isOpen={showIXGSync}
        onClose={() => setShowIXGSync(false)}
      />
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  paper: {
    padding: '5px',
    height: '100%'
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    marginBottom: '20px'
  },
  dataGrid: {
    '& .custom-column': {
      backgroundColor: '#f5f5f5',
      border: '1px solid #ccc'
    }
  },
  icon: {
    fontSize: 24
  },
  avatars: {
    width: 40,
    height: 30,
    marginRight: '5px',
    backgroundColor: 'transparent'
  },
  gwContainer: {
    display: 'left',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '1rem'
  },
  toolboxContainer: {
    display: 'left',
    justifyContent: 'end'
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  viewPermission: {
    display: 'flex',
    fontWeight: 'bold',
    padding: '5px',
    margin: '10px',
    backgroundColor: '#f5f5f5'
  }
};

export default Site;
