import React, { useState } from 'react';
import { Box, BoxProps, InputLabel, InputLabelProps, TextField, TextFieldProps } from '@mui/material';
import { GridColumnHeaderParams } from '@mui/x-data-grid';
import { TimeSelectorType } from '../common';
import { useTranslation } from 'react-i18next';

// Set false to disable validation of the range time on the blur event
const ENFORCE_COERCION_RANGE = true;

interface RelayDelayColHeaderProps {
  value: number;
  onValueChange: (newValue: number) => void;
  inputLabelProps?: InputLabelProps;
  textFieldProps?: TextFieldProps;
  params?: GridColumnHeaderParams;
  headerLabel: string;
  endAdornmentBoxProps?: BoxProps;
  timeType: TimeSelectorType;
  suffix?: string;
}

export const RelayDelayColHeader: React.FC<RelayDelayColHeaderProps> = ({
  value,
  onValueChange,
  inputLabelProps,
  headerLabel,
  textFieldProps,
  params,
  endAdornmentBoxProps,
  timeType,
  suffix
}: RelayDelayColHeaderProps): React.ReactElement => {
  const width = params?.colDef.width;
  const [delayValue, setDelayValue] = React.useState(value); // Should always be in MS
  const [headerText, setHeaderText] = useState(value.toString());
  const [cellLabelColor, setCellLabelColor] = useState('black');
  const [cellLabel, setCellLabel] = useState(headerLabel);

  const { t } = useTranslation();

  const baseValueMS = React.useRef<number>(0);

  React.useEffect(() => {
    // If there is an update to the timeType, convert the text to the new timeType
    // This adjusts/converts the time for s/ms accordingly
    setHeaderText(Math.round(delayValue / 1000).toString());
  }, [timeType]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // We always pass the delay as ms to the backend.
      // This is regardless of the timeType.
      let newText = event.target.value;
      const newValue = event.target.value;
      let numericValue = 0;

      // Defaulting to 5 seconds if this is zero
      if (newText === '') {
        newText = '5';
        baseValueMS.current = 5000;
        setDelayValue(5000);
        setHeaderText(newText);
        return;
      }

      numericValue = parseInt(newValue, 10) * 1000;

      baseValueMS.current = numericValue;
      setDelayValue(numericValue);
      onValueChange(numericValue);
      setHeaderText(newText);
    },
    [onValueChange, timeType, setDelayValue]
  );

  /**
   * A React callback function to temporarily display a custom message by appending a note
   * to the original label, and revert back to the original label after a specified timeout period.
   *
   * @param {string} appendNote - The text to append to the current label, creating a temporary message.
   * @returns {void}
   */
  const displayLimitCrossedMessage = React.useCallback(
    (appendNote: string) => {
      const originalLabel = headerLabel;

      // Update with the new text

      setCellLabel(appendNote);

      // Revert back to normal after 2 seconds
      setTimeout(() => {
        setCellLabel(originalLabel);
      }, 2000); // Display the message for 2 seconds
    },
    [setCellLabel, setCellLabelColor, headerLabel]
  );

  const handleBlur = React.useCallback(() => {
    let numericValue = parseInt(headerText, 10);

    // Enforce default value if empty
    if (isNaN(numericValue)) {
      numericValue = 5;
    }
    // Convert this to ms
    let NumericValueMS = numericValue * 1000;

    // Apply range checks and coercion for min of 3000 MS and max of 600,000 MS
    if (NumericValueMS < 3000) {
      numericValue = 3000;
      displayLimitCrossedMessage(`${t('Min_Secs_')} ${numericValue / 1000}`);
      NumericValueMS = 3000;
    } else if (NumericValueMS > 600000) {
      numericValue = 600000;
      displayLimitCrossedMessage(`${t('Max_Secs_')} ${numericValue / 1000}`);
      NumericValueMS = 600000;
    }

    baseValueMS.current = NumericValueMS;

    setHeaderText(Math.round(NumericValueMS / 1000).toString());
    setDelayValue(NumericValueMS);
    onValueChange(NumericValueMS);
  }, [headerText, setDelayValue, onValueChange, timeType, t, displayLimitCrossedMessage]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: width ?? '100%',
        height: '100%',
        padding: '8px'
      }}
    >
      <InputLabel
        htmlFor="custom-column-header"
        shrink={true}
        {...inputLabelProps}
        sx={{
          fontSize: '0.75rem',
          color: cellLabelColor,
          marginBottom: '0px'
        }}
      >
        {cellLabel}
      </InputLabel>
      <TextField
        id="custom-column-header"
        value={headerText}
        onChange={handleChange}
        onBlur={ENFORCE_COERCION_RANGE ? handleBlur : undefined}
        variant="standard"
        size={'small'}
        InputProps={{
          endAdornment: suffix ? (
            <Box component="span" sx={{ fontSize: '0.75rem', color: 'gray', ...endAdornmentBoxProps?.sx }}>
              {suffix}
            </Box>
          ) : null
        }}
        sx={{
          marginTop: '0px',
          fontSize: '0.75rem',
          color: 'black',
          '& .MuiInput-underline:before': {
            borderBottom: '1px solid gray' // Unfocused
          },
          '& .MuiInput-underline:after': {
            borderBottom: '2px solid black' // Focused
          },
          '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid black' // Hover (not focused)
          },
          ...textFieldProps?.sx
        }}
        onKeyDown={(event) => {
          // Prevent non-digit text entry
          if (timeType === 's' && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(event.key)) {
            // This also checks that there is only one "." in the text input.
            if (!/[0-9.]/.test(event.key) || (event.key === '.' && headerText.split('.').length > 1)) {
              event.preventDefault();
            }
          }
        }}
        onPaste={(e) => {
          e.preventDefault();

          let paste = e.clipboardData.getData('text');

          // Remove all non-digits and non-dots.
          paste = paste.replace(/[^0-9.]/g, '');

          // Remove extra dots from the paste.
          const dotPositions = [];
          for (let i = 0; i < paste.length; i++) {
            if (paste[i] === '.' && dotPositions.length === 0) {
              dotPositions.push(i);
            } else if (paste[i] === '.' && dotPositions.length > 0) {
              paste = paste.substring(0, i) + paste.substring(i + 1, paste.length);
            }
          }

          // Convert the pasted text to a number.

          const numericValueMs = parseInt(paste, 10) * 1000;
          baseValueMS.current = numericValueMs;
          setDelayValue(numericValueMs);
          onValueChange(numericValueMs);
          setHeaderText(paste);
        }}
        inputProps={{
          ...textFieldProps?.inputProps,
          style: {
            fontSize: '0.75rem',
            color: 'gray',
            ...textFieldProps
          },
          inputMode: 'numeric',
          pattern: '[0-9]*'
        }}
      />
    </Box>
  );
};
