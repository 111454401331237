/**
 * Status: DONE
 */
import React from 'react';
import {
  DataGrid,
  GridCellModesModel,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
  GridRowsProp,
  MuiEvent,
  useGridApiRef
} from '@mui/x-data-grid';
import {
  basicContactOutputs,
  DEFAULT_RELAYS,
  defaultContactOutputState,
  handleCellClickToView,
  handleCellModesModelChange,
  handleRowClick,
  TimeSelectorType
} from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/common';
import StationNameNumberCell from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/cells/StationNameNumberCell';
import CheckedCheckbox from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/cells/CheckedCheckbox';
import { useTranslation } from 'react-i18next';
import CustomGridToolbarWithToggle from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/headers/CustomGridToolbarWithToggle';
import { RelayDelayColHeader } from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/headers/RelayDelayColHeader';
import { Check } from '@mui/icons-material';

import { IDevice, IDeviceContactOutput, ILiftControlDeviceOperation } from 'store/slices/devicesSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';

interface PickupFloorLCProps {
  isSubmitting: boolean;
  selectedDevicePublicId: string;
  updateLiftControlDevice: (payload: any) => Promise<void>;
  handleUpdateRowField: (
    id: GridRowId,
    field: string,
    newValue: any,
    setRows: (
      value: ((prevState: readonly GridRowModel[]) => readonly GridRowModel[]) | readonly GridRowModel[]
    ) => void | undefined
  ) => void;
  buildingPublicId: string;
  handleUpdateRelayDelay(
    relaysRef: React.MutableRefObject<IDeviceContactOutput[]>,
    params: GridColumnHeaderParams,
    newRelayMS: number
  ): void;
}

const PickupFloorLC = ({
  isSubmitting,
  selectedDevicePublicId,
  updateLiftControlDevice,
  handleUpdateRowField,
  handleUpdateRelayDelay,
  buildingPublicId
}: PickupFloorLCProps) => {
  const dataGridRef = React.useRef<HTMLDivElement>(null);
  const apiRef = useGridApiRef();
  const [timeSelectorType, setTimeSelectorType] = React.useState<TimeSelectorType>('s');
  const initialData = React.useRef<GridRowsProp>([]);
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);
  const selectedDevice = deviceList[selectedDevicePublicId ?? ''];

  const unitsList = useSelector((state: RootState) => state.units.UnitList);

  const liftControlSettings = selectedDevice.liftControlSettings;

  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState<boolean>(false);

  const arrival: ILiftControlDeviceOperation[] = liftControlSettings?.operation.arrival ?? [];

  const relays = React.useMemo(() => {
    return (selectedDevice.contactOutputList as IDeviceContactOutput[]) ?? DEFAULT_RELAYS;
    // Force update should be triggered by the update to deviceList
  }, [selectedDevice.contactOutputList, deviceList]);

  const relaysRef = React.useRef<IDeviceContactOutput[]>(relays);

  const pickupFloorData = (): ILiftControlDeviceOperation[] => {
    return deviceListByType['EntranceStation']
      .map((deviceId) => {
        const device: IDevice = deviceList[deviceId];

        const devicePublicId = device.liftControlSettings.devicePublicId;

        // Similar to ArrivalLC, filter out the devices that do not have the same building public id
        if (device.buildingPublicId !== buildingPublicId) {
          const unitPublicId = device.unitPublicId;

          if (!unitPublicId) {
            return null;
          } else if (unitsList[unitPublicId].buildingPublicId !== buildingPublicId) {
            return null;
          }
        }

        const arrivalDevice = arrival.find((arrivalDevice) => arrivalDevice.targetDevicePublicId === devicePublicId);

        if (arrivalDevice) {
          return {
            id: deviceId,
            stationName: device.basicInfo.stationName,
            stationNumber: device.basicInfo.stationNumber,
            ...arrivalDevice
          };
        } else {
          return {
            ...defaultContactOutputState,
            targetDevicePublicId: devicePublicId,
            id: deviceId,
            stationName: device.basicInfo.stationName,
            stationNumber: device.basicInfo.stationNumber
          };
        }
      })
      .filter((device) => device !== null) as ILiftControlDeviceOperation[];
  };

  const pickupFloors = pickupFloorData();

  const [rows, setRows] = React.useState<GridRowsProp>(pickupFloors);

  const columns: GridColDef[] = React.useMemo(() => {
    const firstColumn: GridColDef = {
      field: 'stations',
      editable: false,
      hideable: false,
      disableColumnMenu: true,
      minWidth: 160,
      pinnable: true,
      groupable: false,
      sortable: false,
      disableReorder: true,
      hideSortIcons: true,
      filterable: false,
      headerClassName: 'font-bold unselectable pl_16px',
      headerName: t('Stations'),
      renderCell: (params: GridRenderCellParams) => {
        return <StationNameNumberCell title={params.row.stationName} subtitle={params.row.stationNumber} />;
      },
      renderEditCell: (params: GridRenderCellParams) => {
        return <StationNameNumberCell title={params.row.stationName} subtitle={params.row.stationNumber} />;
      },
      cellClassName: 'unselectable m-0 p-0'
    };

    const relayCols: GridColDef[] = relays.map((relay, index) => {
      const relayNum = index + 1;
      return {
        field: `contactOutput${relayNum}Enabled`,
        sortable: false,
        disableReorder: true,
        hideSortIcons: true,
        filterable: false,
        pinnable: true,
        groupable: false,
        disableColumnMenu: true,
        type: 'boolean',
        editable: true,
        headerClassName: 'm-0 p-0',
        hideable: false,
        cellClassName: 'm-0 p-0',
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <RelayDelayColHeader
              params={params}
              timeType={timeSelectorType}
              headerLabel={`${t('Relay')} ${relayNum}`}
              value={relay.timer}
              onValueChange={(newValue: number) => {
                handleUpdateRelayDelay(relaysRef, params, newValue);
              }}
              suffix={timeSelectorType.toString()}
            />
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CheckedCheckbox
              key={params.id}
              initialChecked={params.row[params.field] || false}
              onToggle={async (newChecked: boolean) => {
                // Update state of rows
                handleUpdateRowField(params.id, params.field, newChecked, setRows);
                // Update the DataGrid state
                await params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newChecked
                });
              }}
              {...params}
            />
          );
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return (
            <CheckedCheckbox
              key={params.id}
              initialChecked={params.row[params.field] || false}
              onToggle={async (newChecked: boolean) => {
                // Update the state of rows
                handleUpdateRowField(params.id, params.field, newChecked, setRows);
                // Update the DataGrid state
                await params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newChecked
                });
              }}
              {...params}
            />
          );
        }
      };
    });

    return [firstColumn, ...relayCols];
  }, [handleUpdateRelayDelay, handleUpdateRowField, timeSelectorType, t]);

  const submitUpdateDevice = React.useCallback(async () => {
    const arrival = [];

    // Go through the rows and get the targetDevicePublicId, and the contactOutput<n>Enabled setting
    const rows = apiRef.current?.getRowModels();

    // Prep payloads
    if (rows) {
      for (const row of rows.values()) {
        const targetDevicePublicId = row.targetDevicePublicId as string;
        const arrivalOutputs: Record<string, string | boolean> = {};
        for (const contactOutputKey of basicContactOutputs) {
          arrivalOutputs[contactOutputKey] = row[contactOutputKey] as boolean;
        }
        arrivalOutputs['targetDevicePublicId'] = targetDevicePublicId;

        arrival.push(arrivalOutputs);
      }
    }

    const existingDevice = deviceList[selectedDevicePublicId];
    const payload = {
      device: {
        publicId: selectedDevicePublicId,
        contactOutputList: relaysRef.current,
        liftControlSettings: {
          devicePublicId: selectedDevicePublicId,
          operation: {
            ...existingDevice.liftControlSettings.operation,
            arrival
          }
        }
      }
    };

    // Handling try catch in updateLiftControlDevice
    await updateLiftControlDevice(payload);
  }, [updateLiftControlDevice, selectedDevicePublicId, relaysRef, apiRef]);

  React.useEffect(() => {
    setIsLoading(true);
    const initialRowData = pickupFloorData();
    // Use this to compare the state on unmount
    initialData.current = initialRowData;
    setRows(initialRowData);
    setIsLoading(false);
  }, [deviceList]);

  return (
    <>
      <DataGrid
        sx={{
          flex: 1,
          overflowX: 'scroll'
        }}
        components={{
          Toolbar: () => (
            <CustomGridToolbarWithToggle
              isSubmitting={isSubmitting}
              description={t('Select_output_Trigger_make_call_IXG')}
              valueToggle={
                // We need to set the opposite value of the current timeSelectorType
                // because the toggle will change the value after the state is updated
                [
                  { value: 's', label: t('Seconds_Text') },
                  { value: 'ms', label: t('Milliseconds_Text') }
                ]
              }
              buttonLeftIcon={<Check />}
              label={t('Seconds_text_full')}
              buttonText={t('Button_SaveChanges')}
              submitHandler={async () => await submitUpdateDevice()}
              selectedValue={timeSelectorType === 's' ? t('Seconds_Text') : t('Milliseconds_Text')}
              handleSelectChange={(event) => {
                setTimeSelectorType(event.target.value as TimeSelectorType);
              }}
            />
          )
        }}
        cellModesModel={cellModesModel}
        onRowClick={(params, event) => handleRowClick(params, event as unknown as MuiEvent<MouseEvent>)}
        onCellModesModelChange={(cellModesModel) => handleCellModesModelChange(cellModesModel, setCellModesModel)}
        showCellVerticalBorder={true}
        showColumnVerticalBorder={true}
        columns={columns}
        autoHeight={true}
        rows={rows}
        ref={dataGridRef}
        apiRef={apiRef}
        loading={isLoading}
        onCellClick={(params, event) => handleCellClickToView(params, event as React.MouseEvent, setCellModesModel)}
      />
      <Dialog disableEscapeKeyDown={true} open={hasUnsavedChanges} onBackdropClick={undefined}>
        <DialogTitle>{t('Save_the_changes')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('Discard_text')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setHasUnsavedChanges(false);
            }}
            variant={'outlined'}
          >
            {t('Discard_Changes')}
          </Button>
          <Button
            onClick={(event) => {
              setHasUnsavedChanges(false);
              event.preventDefault();
            }}
            variant={'contained'}
          >
            {t('Keep_Editing')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PickupFloorLC;
