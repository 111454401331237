/**
 * Common regular expressions
 */

// NANP phone number regex
// North American Numbering Plan (NANP) phone number regex
// https://en.wikipedia.org/wiki/North_American_Numbering_Plan
// 10-23-24: Just edited so it only permits +1 as the country code.
/**
 * @constant NANP_PHONE_REGEX
 * @description Regular expression to validate North American Numbering Plan (NANP) phone number.
 * The [NANP](https://en.wikipedia.org/wiki/North_American_Numbering_Plan) is a telephone numbering plan that encompasses 25 distinct regions in twenty countries primarily in North America, including the Caribbean and the U.S. territories.
 * This iteration only permits `+1` as the country code, as per our standard.
 */
export const NANP_PHONE_REGEX = /^\+1 \([2-9]\d{2}\) [2-9]\d{2}-\d{4}$/;

/**
 * @constant EMAIL_REGEX
 * @description Regular expression to validate email address
 */
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * @constant VALID_STATION_NAME_REGEX
 * @description Regular expression to validate URL
 */
export const VALID_STATION_NAME_REGEX = /^[A-Za-z0-9+\-_?./()%$@! ]{1,24}$/;

/**
 * @constant VALID_MAC_ADDRESS_REGEX
 * @description Regular expression to validate MAC address
 */
export const VALID_MAC_ADDRESS_REGEX = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;

/**
 * An object containing regular expressions for validating ZIP or postal codes of specific countries.
 *
 * @property {RegExp} canada - The regular expression for validating Canadian postal codes.
 *                              It ensures the format follows the pattern of alternating letters
 *                              and digits, excludes certain letters (DFIOQU), and optionally
 *                              includes a space.
 *
 * @property {RegExp} unitedStates - The regular expression for validating United States ZIP codes.
 *                                   It ensures the format includes five numeric digits and optionally
 *                                   a hyphen followed by four additional numeric digits.
 */
export const ZIP_CODE_REGEX = {
  canada: /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/,
  unitedStates: /^[0-9]{5}(?:-[0-9]{4})?$/
};

/**
 * First, the `matches` function checks if the phone number matches the provided regular expression:
 * - It may start with an optional "+" sign.
 * - Subsequently, it may have 1 to 3 digits which can be followed by a special character `-` or `.` or space.
 * - This can be followed by another set of 1 to 4 digits which can be optionally enclosed in parentheses `( )`, again followed by a `-` or `.` or space.
 * - This pattern repeats for groups of 1-4 digits.
 * - Finally, it ends with 1 to 9 digits.
 *
 * Some valid examples would be:
 * - `+1-800-123-4567`
 * - `+91 12345 12345`
 * - `+(44) 20 1234 1234`
 * - `123 456 7890`
 * - `1234567890`
 * - `+123.456.7890`
 * - `(123)456-7890`
 * - `123.456.7890`
 *
 * This regular expression aims to validate a wide range of international and local phone number formats that include varying digit groups and special characters such as the plus sign (+), dash (-), period(.) , whitespace and parentheses.
 */
export const INTERNATIONAL_PHONE_REGEX =
  /^\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
