import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { UUID } from 'crypto';
import { IEntrancePanelPayload } from '../../QRAccessCodes';
import { useEffect, useState } from 'react';

interface IEntranceStationCard {
  devicePublicId: UUID | string;
  unitPublicId: UUID | string;
  sharedAccessCode: string;
  entrancePanelsToUpdate: IEntrancePanelPayload;
  setEntrancePanelsToUpdate: (value: IEntrancePanelPayload) => void;
}

const EntranceStationCard = ({
  devicePublicId,
  unitPublicId,
  sharedAccessCode,
  entrancePanelsToUpdate,
  setEntrancePanelsToUpdate
}: IEntranceStationCard) => {
  const [isQrCodeEnabled, setIsQrCodeEnabled] = useState<boolean>(false);
  const [newAccessCode, setNewAccessCode] = useState<string>('');
  const device = useSelector((state: RootState) => state.devices.DeviceList[devicePublicId]);
  const listOfCodes = device.entrancePanelSettings?.displayUnlockCodeList;
  const accessCodeForThisUnit = listOfCodes?.find((code) => code.unitPublicId === unitPublicId);
  const imageUrl = `https://aiphone-images.s3.us-west-2.amazonaws.com/icons/${getDeviceModelNumberFromModelType(
    device?.basicInfo?.deviceModel,
    device?.basicInfo?.deviceType
  )}.png`;

  useEffect(() => {
    if (device) {
      setNewAccessCode(accessCodeForThisUnit?.accessCode || '');
      setIsQrCodeEnabled(device.entrancePanelSettings?.displayQrCodeScan || false);
    }
  }, [devicePublicId]);

  useEffect(() => {
    if (sharedAccessCode) {
      setNewAccessCode(sharedAccessCode);
    }
  }, [sharedAccessCode]);
  useEffect(() => {
    const updatedCodeList = [
      // If the unitPublicId is already in the list, update the access code.
      ...(listOfCodes?.filter((code) => code.unitPublicId !== unitPublicId) || [])
    ];

    // Only add a new entry if the access code is not empty
    if (newAccessCode !== '') {
      updatedCodeList.push({
        devicePublicId: devicePublicId,
        unitPublicId: unitPublicId,
        accessCode: newAccessCode
      });
    }

    const entrancePanelToUpdatePayload: IEntrancePanelPayload = {
      [devicePublicId]: {
        ...entrancePanelsToUpdate[devicePublicId],
        displayUnlockCodeList: updatedCodeList
      }
    };

    setEntrancePanelsToUpdate((prevEntrancePanelsToUpdate: IEntrancePanelPayload) => {
      return {
        ...prevEntrancePanelsToUpdate,
        ...entrancePanelToUpdatePayload
      };
    });
  }, [newAccessCode]);

  const handleAccessCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAccessCode(event.target.value);
  };

  const handleQrToggle = () => {
    setIsQrCodeEnabled(!isQrCodeEnabled);
    const entrancePanelToUpdatePayload: IEntrancePanelPayload = {
      [devicePublicId]: {
        ...entrancePanelsToUpdate[devicePublicId],
        qrCodeEnabled: !isQrCodeEnabled
      }
    };
    setEntrancePanelsToUpdate({ ...entrancePanelsToUpdate, ...entrancePanelToUpdatePayload });
  };

  return (
    <Card>
      <Grid sx={styles.topBarWrapper}>
        <Grid sx={styles.stationNameWrapper}>
          <Typography variant="h5">{device?.basicInfo?.stationName}</Typography>
        </Grid>
      </Grid>

      <CardContent>
        <Grid sx={styles.deviceWrapper} container alignItems="center">
          <Grid sx={styles.deviceInfoWrapper}>
            <Typography variant="body2" color="text.secondary">
              <strong>Model Number: </strong>
              {getDeviceModelNumberFromModelType(device?.basicInfo?.deviceModel, device?.basicInfo?.deviceType)}
            </Typography>

            <Typography variant="body2" color="text.secondary">
              <strong>Station Number: </strong>
              {device?.basicInfo?.stationNumber}
            </Typography>
          </Grid>
          <Grid sx={styles.deviceImageWrapper}>
            <Box component="img" src={imageUrl} />
          </Grid>
          <Grid container>
            <Grid item xs={6} sx={styles.centerContent}>
              <TextField
                id="outlined-basic"
                label="Access PIN"
                variant="outlined"
                value={newAccessCode}
                onChange={handleAccessCodeChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} sx={styles.centerContent}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch value={isQrCodeEnabled} checked={isQrCodeEnabled} onClick={handleQrToggle} defaultChecked />
                  }
                  label="Enable QR Access"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const styles = {
  topBarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  stationNameWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '15px'
  },
  deviceWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  deviceInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  deviceImageWrapper: {
    MarginLeft: '40px',
    p: 2
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default EntranceStationCard;
