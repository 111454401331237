import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  GridValueOptionsParams,
  useGridApiRef
} from '@mui/x-data-grid';
import * as uuid from 'uuid';
import { getModelNumberOptions } from 'shared/utils/helperFunctions';
import React, { useEffect, useMemo, useState } from 'react';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { t } from 'i18next';
import 'styles/frontshine.css';
import TextInputDataGridCell, { NonEditableTextInputDataGridCell } from 'shared/components/TextInputDataGridCell';

const MAC_ADDRESS_PREFIX = '00:0B:AA:';

const initialRows: GridRowsProp = [];

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

interface Props {
  manualSelectedDevices: GridRowModel[];
  setManualSelectedDevices: (devices: GridRowModel[]) => void;
  restrictedMacAddresses: string[];
  deviceTypeFilter?: string[];
  selectedUnitType?: number;
  allowedLcCount?: number;
  maxStationCountsForUnit?: { [key: string]: number };
  currentStationCountsForUnit?: { [key: string]: number };
  devicesResetTrigger?: number;
  setIsAtDeviceLimit?: (isAtLimit: boolean) => void;
  selectedBuildingNumber?: string;
}

const AddDeviceManuallyDataGrid = ({
  manualSelectedDevices,
  setManualSelectedDevices,
  restrictedMacAddresses,
  deviceTypeFilter,
  selectedUnitType,
  allowedLcCount,
  maxStationCountsForUnit,
  currentStationCountsForUnit,
  devicesResetTrigger,
  setIsAtDeviceLimit: parentSetIsAtDeviceLimit
}: Props) => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [isBatchAddDialogOpen, setIsBatchAddDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [batchType, setBatchType] = useState('');
  const [batchModel, setBatchModel] = useState('');
  const [batchQuantity, setBatchQuantity] = useState(1);
  const [error, setError] = useState<string | null>(null);
  const [isAtDeviceLimit, setIsAtDeviceLimit] = useState(false);
  const [isDeviceTypeAllowed, setIsDeviceTypeAllowed] = useState(true);
  const selectedDeviceIds = useMemo(() => {
    if (manualSelectedDevices && manualSelectedDevices.length > 0) {
      return manualSelectedDevices.map((device) => device.id);
    } else {
      return [];
    }
  }, [manualSelectedDevices]);
  const dataGridRef = React.useRef<HTMLDivElement>(null);
  const apiRef = useGridApiRef();

  const setCurrentRows = () => {
    if (manualSelectedDevices && manualSelectedDevices.length > 0) {
      return manualSelectedDevices;
    }
    return initialRows;
  };

  const updateIsAtDeviceLimit = (limitReached: boolean) => {
    setIsAtDeviceLimit(limitReached);
    if (parentSetIsAtDeviceLimit) {
      parentSetIsAtDeviceLimit(limitReached);
    }
  };
  const [rows, setRows] = useState(setCurrentRows());

  useEffect(() => {
    const editing = Object.values(rowModesModel).some((row) => row.mode === GridRowModes.Edit);
    setIsEditing(editing);
  }, [rowModesModel]);

  useEffect(() => {
    setIsEditing(false);
    setRowModesModel({});
  }, [devicesResetTrigger]);

  const disallowedDeviceTypes: { [key: number]: string[] } = {
    1: ['Video Master Station', 'Tenant Station', 'Video Entrance Station'],
    2: [
      'Video Master Station',
      'Sub Master Station',
      'Tenant Station',
      'Video Guard Station',
      'Video Door Station',
      'Audio Door Station',
      'Emergency Station',
      'Stainless Steel Audio Door Station',
      'Mullion Video Door Station',
      'Plastic Video Door Station'
    ],
    3: ['Tenant Station', 'Video Entrance Station'],
    4: ['Video Master Station', 'Video Guard Station', 'Video Entrance Station'],
    5: ['Tenant Station', 'Video Guard Station', 'Video Entrance Station'],
    6: ['Video Master Station', 'Sub Master Station', 'Tenant Station', 'Video Guard Station', 'Video Entrance Station']
  };

  const isDeviceTypeDisallowed = (deviceTypeFilter: string[], disallowedTypes: string[]): boolean => {
    return disallowedTypes.some((type) => deviceTypeFilter.includes(type));
  };

  useEffect(() => {
    if (deviceTypeFilter !== undefined && selectedUnitType !== undefined) {
      const disallowedTypes = disallowedDeviceTypes[selectedUnitType] || [];
      const isDisallowed = deviceTypeFilter && isDeviceTypeDisallowed(deviceTypeFilter, disallowedTypes);

      setIsDeviceTypeAllowed(!isDisallowed);
    }
  }, [selectedUnitType, deviceTypeFilter]);

  const deviceTypes = [
    { value: 'IX-MV7', label: t('Master_Station') },
    { value: 'IX-RS', label: t('Sub_Master_Station') },
    { value: 'IX-DV', label: t('Video_Door_Station') },
    { value: 'IX-SSA', label: t('Audio_Door_Station') },
    { value: 'IXW-MA', label: t('IO_Network_Adaptor') },
    { value: 'IXG-DM7', label: t('Entrance_Station') },
    { value: 'IXG-MK', label: t('Guard_Station') },
    { value: 'IXG-2C7', label: t('Tenant_Station') },
    { value: 'IXGW-LC', label: t('Lift_Controller') },
    { value: 'IXGW-GW', label: t('Gateway') }
  ];
  let filteredDeviceTypes = deviceTypes;
  if (deviceTypeFilter) {
    filteredDeviceTypes = deviceTypes.filter((type) => deviceTypeFilter.includes(type.label));
  }

  const validateMacAddress = React.useCallback(
    (value: string, rowId: string) => {
      if (!value) {
        return t('MAC_Address_is_required');
      }

      if (value.length !== 17) {
        return t('Must_be_17_characters');
      }
      const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
      if (!macAddressRegex.test(value)) {
        return t('Please_Reformat_mac_address');
      }

      if (restrictedMacAddresses.includes(value)) {
        return t('In_use');
      }

      const apiRefRows = apiRef.current?.getRowModels();

      // Check for duplicate MAC addresses
      let isUnique = true;
      for (const value of apiRefRows.values()) {
        if (value.mac_addr === value && value.id !== rowId) {
          isUnique = false;
          break;
        }
      }

      if (!isUnique) {
        return t('MAC_Address_Must_be_Unique');
      }

      return null;
    },
    [t, rows, restrictedMacAddresses, apiRef]
  );

  const checkDeviceLimit = (rows: GridRowModel[]) => {
    const isDeviceTypeFilterArray = deviceTypeFilter && Array.isArray(deviceTypeFilter);
    let hasLimitReached = false;
    switch (isDeviceTypeFilterArray ? deviceTypeFilter[0] : deviceTypeFilter) {
      case 'Video Master Station':
      case 'Sub Master Station':
      case 'Tenant Station':
      case 'Guard Station':
        if (
          maxStationCountsForUnit &&
          currentStationCountsForUnit &&
          rows.length > maxStationCountsForUnit.answeringStations - currentStationCountsForUnit.answeringStations
        ) {
          hasLimitReached = true;
        }
        break;
      case 'Video Door Station':
      case 'Audio Door Station':
      case 'Emergency Station':
      case 'Stainless Steel Audio Door Station':
      case 'Mullion Video Door Station':
      case 'Plastic Video Door Station':
        if (
          maxStationCountsForUnit &&
          currentStationCountsForUnit &&
          rows.length > maxStationCountsForUnit.doorStations - currentStationCountsForUnit.doorStations
        ) {
          hasLimitReached = true;
        }
        break;
      case 'Entrance Station':
        if (
          maxStationCountsForUnit &&
          currentStationCountsForUnit &&
          rows.length > maxStationCountsForUnit.entranceStations - currentStationCountsForUnit.entranceStations
        ) {
          hasLimitReached = true;
        }
        break;
      default:
        break;
    }

    updateIsAtDeviceLimit(hasLimitReached);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    checkDeviceLimit([...rows]);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
    checkDeviceLimit(updatedRows);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel) => {
      const errorMessage = validateMacAddress(newRow.mac_addr, newRow.id);
      if (errorMessage) {
        newRow.error = errorMessage;
        return { ...newRow, isNew: true };
      }
      const apiRefRows = apiRef.current?.getRowModels();
      let isMacAddressDuplicate = false;
      for (const value of apiRefRows.values()) {
        if (value.mac_addr === newRow.mac_addr && value.id !== newRow.id) {
          isMacAddressDuplicate = true;
          break;
        }
      }
      if (isMacAddressDuplicate) {
        newRow.error = t('MAC_Address_Must_be_Unique');
        setError(t('MAC_Address_Must_be_Unique'));
        return { ...newRow, isNew: true };
      } else {
        setError(null);
      }

      const updatedRow = { ...newRow, isNew: false, error: undefined };
      const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
      setRows(updatedRows);
      checkDeviceLimit(updatedRows);
      return updatedRow;
    },
    [validateMacAddress, rows, restrictedMacAddresses, apiRef, checkDeviceLimit, t]
  );

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleManualDeviceSelection = (newSelection: GridRowId[]) => {
    const selectedRows = rows.filter((row) => newSelection.includes(row.id));
    setManualSelectedDevices(selectedRows);
  };

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
    const handleAddDevice = () => {
      const id = uuid.v4();
      setRows((oldRows) => [
        ...oldRows,
        {
          id,
          mac_addr: MAC_ADDRESS_PREFIX,
          device_type: '',
          model_number: '',
          isNew: true
        }
      ]);
      // Commenting out this code removes the "autofocus" effect on the row,
      // which allows us to provide initial validation
      //
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, isNew: true }
      }));
    };

    const handleBatchAddDevice = () => {
      setBatchModel('');
      setBatchQuantity(1);
      setBatchType('');
      isBatchAddDialogOpen ? setIsBatchAddDialogOpen(false) : setIsBatchAddDialogOpen(true);
    };

    return (
      <GridToolbarContainer>
        <Tooltip
          title={
            isEditing
              ? t('Only_One_device_at_a_type')
              : '' || isAtDeviceLimit
              ? t('Maximum_number_of_devices_reached')
              : ''
          }
        >
          <span>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddDevice}
              disabled={isEditing || isAtDeviceLimit || !isDeviceTypeAllowed}
            >
              {t('Add_Device')}
            </Button>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleBatchAddDevice}
              disabled={isEditing || isAtDeviceLimit || !isDeviceTypeAllowed}
            >
              {t('Batch_Add_Devices')}
            </Button>
          </span>
        </Tooltip>
      </GridToolbarContainer>
    );
  }

  const _initialMountCheckMACAddress = React.useCallback(
    (macAddress: string | undefined, rowId: string) => {
      if (macAddress) {
        return validateMacAddress(macAddress, rowId);
      }
      return null;
    },
    [t]
  );

  const preprocessMacAddressCell = React.useCallback(
    (props: GridPreProcessEditCellProps) => {
      const errorMessage = validateMacAddress(props.props.value as string, props.props.id);
      return { ...props.props, error: errorMessage };
    },
    [validateMacAddress]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: 'mac_addr',
        headerName: t('MAC_Address'),
        width: 180,
        editable: true,
        valueParser(value: any) {
          let formattedMac = value.replace(/[^0-9A-Fa-f]/g, '').toUpperCase();
          if (formattedMac.length > 12) formattedMac = formattedMac.slice(0, 12);
          return formattedMac.match(/.{1,2}/g)?.join(':') || formattedMac;
        },
        preProcessEditCellProps: preprocessMacAddressCell,
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell error={_initialMountCheckMACAddress(props.value, props.row.id)} {...props} />
        ),
        renderCell: (props: GridRenderEditCellParams) => <NonEditableTextInputDataGridCell {...props} />
      },
      {
        field: 'device_type',
        headerName: t('Device_Type'),
        width: 220,
        editable: true,
        type: 'singleSelect',
        valueOptions: filteredDeviceTypes,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.value) {
            return <small className={'text-left text-xs'}>{t('Select_Device_Type')}</small>;
          } else {
            // Show the device type label: Audio Door Station or Video Door Station
            return params.label;
          }
        }
      },
      {
        field: 'model_number',
        headerName: t('DeviceCard_ModelNumber'),
        width: 250,
        editable: true,
        type: 'singleSelect',
        valueOptions: (params: GridValueOptionsParams) => getModelNumberOptions(params.row.device_type),
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          return { ...params.props };
        },
        renderCell(params: GridRenderCellParams) {
          const modelName: string = params.value === 'IXG-DM7-HID(A)' ? 'IXG-DM7-HID' : params.value;
          const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${modelName}.png`;
          if (params.value) {
            return (
              <MenuItem sx={{ width: '100%' }}>
                {params.value ? (
                  <ListItemIcon sx={styles.stationIcons}>
                    <img src={imgUrl} alt="" />
                  </ListItemIcon>
                ) : (
                  <></>
                )}
                <ListItemText primary={params.value} />
              </MenuItem>
            );
          } else {
            return <small className={'text-left text-xs'}>{t('Please_Select_Device')}</small>;
          }
        },
        renderEditCell(params) {
          const id = `model_number_${params.row.id}`;
          return (
            <Select
              variant={'outlined'}
              SelectDisplayProps={{
                style: { display: 'flex', alignItems: 'center' }
              }}
              value={params.value}
              id={id}
              onChange={async (event) => {
                await params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: event.target.value
                });
              }}
              inputProps={{
                'aria-label': t('Select_Model_Number')
              }}
              fullWidth
              notched
              placeholder={t('Select_Model_Number')}
              disabled={params.row.device_type === ''}
            >
              {getModelNumberOptions(params.row.device_type).map((option) => {
                const modelName: string = option === 'IXG-DM7-HID(A)' ? 'IXG-DM7-HID' : option;
                const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${modelName}.png`;
                return (
                  <MenuItem key={option} value={option} disableGutters sx={{ width: '100%' }}>
                    <ListItemIcon sx={styles.stationIcons}>
                      <img src={imgUrl} alt="" />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                );
              })}
            </Select>
          );
        }
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: t('Actions'),
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                key={'save'}
                icon={<SaveIcon />}
                label={t('Button_Save')}
                sx={{
                  color: 'primary.main'
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                key={'cancel'}
                icon={<CancelIcon />}
                label={t('Button_Cancel')}
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              key={'edit'}
              label={t('Edit')}
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={isEditing}
            />,
            <GridActionsCellItem
              key={'delete'}
              icon={<DeleteIcon />}
              label={t('Delete')}
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          ];
        }
      }
    ],
    [validateMacAddress, t, rowModesModel, rows, isEditing, isAtDeviceLimit, isDeviceTypeAllowed]
  );

  const sanitizeQuantityInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value.length > 1) event.target.value = event.target.value.replace(/^0+/, ''); // trim any leading zeros (eg. 01, 001, etc)
    if (Number(event.target.value) < 1)
      // disallow negative values
      event.target.value = '1';

    if (batchModel === 'IXGW-LC' && Number(event.target.value) > (allowedLcCount ?? 0)) {
      event.target.value = (allowedLcCount ?? 0).toString();
    }
    setBatchQuantity(Number(event.target.value));
  };

  const disallowSpecialCharPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const disallowedKeysRegex = /[.+-]/;
    const shouldPrevent = disallowedKeysRegex.test(event.key);
    if (shouldPrevent) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Box
        sx={{
          height: 550,
          width: '100%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          }
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          apiRef={apiRef}
          ref={dataGridRef}
          getRowId={(row) => row.id}
          editMode="row"
          checkboxSelection
          disableRowSelectionOnClick
          rowModesModel={rowModesModel}
          rowSelectionModel={selectedDeviceIds}
          onRowModesModelChange={handleRowModesModelChange}
          onRowSelectionModelChange={handleManualDeviceSelection}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
            noRowsOverlay: () => (
              <Box sx={styles.noStation}>
                <Typography variant="h6">
                  {!isDeviceTypeAllowed ? t('Device_Type_not_supported') : t('No_Stations_added_yet')}
                </Typography>
                <Typography variant="body1">
                  {!isDeviceTypeAllowed ? t('Select_a_Different_device') : t('Click_the')}
                  {t('_')}
                  <strong>{t('Add_Device')}</strong>
                  {t('_')}
                  {t('Button_to_start_adding_stations')}
                </Typography>
              </Box>
            )
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel }
          }}
          isRowSelectable={(params) =>
            !params.row.isNew &&
            params.row.device_type !== '' &&
            params.row.model_number !== '' &&
            params.row.mac_addr !== ''
          }
        />
        <div className={'flex w-full justify-start items-start mt-2'}>
          {error && <small className={'text-red text-left text-size_14px'}>{error}</small>}
        </div>
      </Box>
      <Dialog
        open={isBatchAddDialogOpen}
        onClose={() => {
          setBatchQuantity(1);
          setBatchModel('');
          setBatchType('');
          setIsBatchAddDialogOpen(false);
        }}
        maxWidth="md"
      >
        <DialogTitle>{t('Batch_Add_Devices')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('Select_a_Device_Type_model_number')}</DialogContentText>
          <Box sx={styles.mtmb2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="device-type-label">{t('Select_Device_Type')}</InputLabel>
                  <Select
                    variant={'outlined'}
                    labelId="device-type-label"
                    id="device-type"
                    value={batchType}
                    onChange={(event) => {
                      setBatchType(event.target.value);
                    }}
                    inputProps={{
                      'aria-label': t('Select_Device_Type')
                    }}
                    fullWidth
                    label={t('Select_Device_Type')}
                  >
                    {filteredDeviceTypes.map((option) => {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="model-number-batch-label">{t('Select_Model_Number')}</InputLabel>
                  <Select
                    variant={'outlined'}
                    labelId="model-number-batch-label"
                    id="model-number-batch"
                    SelectDisplayProps={{
                      style: { display: 'flex', alignItems: 'center' }
                    }}
                    value={batchModel}
                    onChange={(event) => {
                      setBatchModel(event.target.value);
                    }}
                    inputProps={{
                      'aria-label': t('Select_Model_Number')
                    }}
                    label={t('Select_Model_Number')}
                    fullWidth
                    disabled={batchType === ''}
                  >
                    {getModelNumberOptions(batchType).map((option) => {
                      const model: string = option === 'IXG-DM7-HID(A)' ? 'IXG-DM7-HID' : option;
                      const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${model}.png`;
                      return (
                        <MenuItem key={option} value={option} sx={styles.width100}>
                          <ListItemIcon sx={styles.stationIcons}>
                            <img src={imgUrl} alt="" />
                          </ListItemIcon>
                          <ListItemText primary={option} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('Quantity_full')}
                  type="number"
                  value={batchQuantity}
                  onKeyDown={(event) => disallowSpecialCharPress(event)}
                  onChange={(event) => sanitizeQuantityInput(event)}
                  fullWidth
                  disabled={
                    batchType === '' ||
                    batchModel === '' ||
                    (batchModel === 'IXGW-LC' && batchQuantity >= (allowedLcCount ?? 0))
                  }
                  inputProps={{
                    max: batchModel === 'IXGW-LC' ? allowedLcCount : undefined
                  }}
                  helperText={
                    batchModel === 'IXGW-LC'
                      ? batchQuantity < (allowedLcCount ?? 0)
                        ? `Remaining devices: ${(allowedLcCount ?? 0) - batchQuantity}`
                        : t('Limit_Reached')
                      : t('Enter_a_number')
                  }
                  error={batchModel === 'IXGW-LC' && batchQuantity > (allowedLcCount ?? 0)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsBatchAddDialogOpen(false);
              setBatchModel('');
              setBatchType('');
              setBatchQuantity(1);
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              const newRows: {
                id: string;
                mac_addr: string;
                device_type: string;
                model_number: string;
                isNew: boolean;
              }[] = [];
              for (let i = 0; i < batchQuantity; i++) {
                const id = uuid.v4();
                newRows.push({
                  id,
                  mac_addr: MAC_ADDRESS_PREFIX,
                  device_type: batchType,
                  model_number: batchModel,
                  isNew: true
                });
              }
              setRows((oldRows) => [...oldRows, ...newRows]);
              setBatchModel('');
              setBatchType('');
              setBatchQuantity(1);
              setIsBatchAddDialogOpen(false);
            }}
            disabled={
              batchType === '' ||
              batchModel === '' ||
              batchQuantity === 0 ||
              (batchModel === 'IXGW-LC' && batchQuantity > (allowedLcCount ?? 0))
            }
          >
            {t('Batch_Add_Devices')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  stationIcons: {
    minWidth: '80px !important',
    display: 'flex',
    justifyContent: 'center'
  },
  mtmb2: {
    mt: 2,
    mb: 2
  },

  width100: {
    width: '100%'
  },
  noStation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    p: 3
  }
};

export default AddDeviceManuallyDataGrid;
