import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { t } from 'i18next';

interface IMoveOutDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleMoveOutTenant: () => void;
}

const MoveOutDialog = ({ isOpen, setIsOpen, handleMoveOutTenant }: IMoveOutDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5">{t('Move_Out_Title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{t('Move_Out_Description_1')}</Typography>
        <ul>
          <li>{t('Move_Out_Description_2')}</li>
          <li>{t('Move_Out_Description_3')}</li>
          <li>{t('Move_Out_Description_4')}</li>
          <li>{t('Move_Out_Description_5')}</li>
          <li>{t('Move_Out_Description_6')}</li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)}>{t('Button_Cancel')}</Button>
        <Button onClick={handleMoveOutTenant} color="primary" variant="contained">
          {t('Move_out_Tenants')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveOutDialog;
