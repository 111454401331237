import { Box, Button } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridRowId, GridRowParams, GridToolbarContainer } from '@mui/x-data-grid';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import EditTenantDialog from 'features/RemoteManagement/SiteDashboard/TenantManagement/Tenants/components/EditTenantDialog';
import {
  useBatchUpdateDevicesMutation,
  useDeleteTenantMutation,
  useLazyGetTenantListWithSiteIdQuery,
  useLazyGetUnitListWithSitePublicIdQuery,
  useMoveOutMutation,
  useUpdateUnitMutation
} from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { getCurrentUser } from 'store/slices/usersSlice';
import { useTranslation } from 'react-i18next';
import MoveOutDialog from '../dialogs/MoveOutDialog';
import CONFIG from 'config';
import { unitTypeMapping } from 'store/slices/unitsSlice';

interface ITenantsDatagridProps {
  setIsAddTenantDialogOpen: (isOpen: boolean) => void;
}

const TenantsDatagrid = ({ setIsAddTenantDialogOpen }: ITenantsDatagridProps) => {
  const [isEditTenantDialogOpen, setIsEditTenantDialogOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<any[]>([]);
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);
  const [selectedTenantId, setSelectedTenantId] = React.useState<string>('');
  const [isMoveOutDialogOpen, setIsMoveOutDialogOpen] = React.useState(false);
  const tenantsList = useSelector((state: RootState) => state.tenants.TenantList);
  const unitPublicId = useParams().unitid;
  const siteId = useParams().id;
  const [fetchTenants] = useLazyGetTenantListWithSiteIdQuery();
  const [fetchUnits] = useLazyGetUnitListWithSitePublicIdQuery();
  const [deleteTenant] = useDeleteTenantMutation();
  const [updateDevices] = useBatchUpdateDevicesMutation();
  const [moveOutTenants] = useMoveOutMutation();
  const [updateUnit] = useUpdateUnitMutation();
  const currentUser = useSelector(getCurrentUser);
  const propertyId = useSelector((state: RootState) => state.site?.siteInfo?.awsPropertyId);
  const entranceStationIdList = useSelector((state: RootState) => state.devices.DeviceListByType['EntranceStation']);
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const appList = useSelector((state: RootState) => state.apps.AppList);
  const { t } = useTranslation();

  const aclToken = localStorage.getItem('acltoken');

  const generateRows = () => {
    return Object.entries(tenantsList)
      .map(([key, tenant]) => {
        if (tenant.unitPublicId !== unitPublicId) return null;

        return {
          id: key,
          firstName: tenant.firstName,
          lastName: tenant.lastName,
          email: tenant.email,
          phone: tenant.phoneNumber,
          isPrimaryTenant: tenant.isPrimaryTenant ? 'Yes' : 'No'
        };
      })
      .filter((row) => row !== null);
  };

  const handleAddTenant = () => {
    setIsAddTenantDialogOpen(true);
  };

  const handleMoveOutTenant = async () => {
    if (unitPublicId) {
      const selectedUnit = unitList[unitPublicId];

      // Delete the tenants in the unit
      try {
        moveOutTenants({ unitPublicId });
      } catch (error: unknown) {
        setError('Failed to move out tenants');
      }

      // TODO: Sync the answering stations ** After gateway communication refactor

      // Change the unit name
      try {
        updateUnit({
          unitPublicId,
          unitData: {
            ...unitList[unitPublicId],
            unitName: `${unitTypeMapping[unitList[unitPublicId].unitType]} ${unitList[unitPublicId].unitNumber}`
          }
        });
      } catch (error: unknown) {
        setError('Failed to update unit name');
      }

      // Delete the DM7 access codes
      const updatedEntranceStations = Object.entries(entranceStationIdList)
        .map(([_, entranceStation]) => {
          const device = deviceList[entranceStation];

          if (device.entrancePanelSettings?.displayUnlockCodeList) {
            const filteredList = device.entrancePanelSettings.displayUnlockCodeList.filter(
              (code) => code.unitPublicId !== unitPublicId
            );

            return {
              publicId: entranceStation,
              entrancePanelSettings: {
                displayUnlockCodeList: filteredList
              },
              sitePublicId: siteId
            };
          }
          return [];
        })
        .flat();

      // Updating DM7 devices
      if (updatedEntranceStations.length > 0) {
        try {
          await updateDevices({ devices: updatedEntranceStations });
        } catch (error: unknown) {
          setError('Failed to update entrance stations');
        }
        // Refetch Devices
      }

      // TODO: Sync DM7 devices ** After gateway communication refactor

      // Sync with ACL
      try {
        await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'leaving',
            userId: currentUser?.publicId,
            propertyId,
            token: aclToken,
            roomId: selectedUnit.unitSequentialNumber,
            unitId: selectedUnit.publicId
          })
        });

        const unitIds = Array.from(Object.entries(appList), ([_, unit]) => unit.unitPublicId);
        const appIds = Array.from(Object.entries(appList), ([id, _]) => id);

        await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'setqrcodes',
            userId: currentUser?.publicId,
            unitIds,
            appIds
          })
        });
      } catch (error: unknown) {
        setError('Failed to sync ACL');
      }

      // Refetch Tenants
      fetchTenants({ sitePublicId: siteId, qty: 500, page: 0 });

      // Refetch Units
      fetchUnits({ sitePublicId: siteId, qty: 500, page: 0 });
    }
  };

  const handleEditTenant = useCallback(
    (id: GridRowId) => async () => {
      await setSelectedTenantId(id as string);
      setIsEditTenantDialogOpen(true);
    },
    []
  );

  const handleDeleteTenant = useCallback(
    (id: GridRowId) => async () => {
      setLoading(true);
      try {
        await deleteTenant(id as string);
        setSuccess('Tenant deleted successfully');
        fetchTenants({ sitePublicId: siteId, qty: 500, page: 0 });
      } catch (err) {
        setError('Failed to delete tenant');
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const columns = [
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'isPrimaryTenant', headerName: 'Primary Tenant', width: 150 },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem label="Edit Tenant" onClick={handleEditTenant(params.id)} showInMenu={true} />,
        <GridActionsCellItem label="Delete Tenant" onClick={handleDeleteTenant(params.id)} showInMenu={true} />
      ]
    }
  ];

  useEffect(() => {
    setRows(generateRows());
  }, [tenantsList]);

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddTenant}>
          {t('Add_Tenant_to_Unit')}
        </Button>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setIsMoveOutDialogOpen(true)}
          disabled={aclToken === null}
        >
          {t('Move_out_Tenants')}
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{
          toolbar: Toolbar,
          noRowsOverlay: () => <Box sx={styles.noRows}>No tenants found for this unit</Box>
        }}
        autoHeight
        loading={loading}
      />
      <EditTenantDialog
        isOpen={isEditTenantDialogOpen}
        setIsOpen={setIsEditTenantDialogOpen}
        selectedTenantId={selectedTenantId}
        setSelectedTenantId={setSelectedTenantId}
      />
      <MoveOutDialog
        isOpen={isMoveOutDialogOpen}
        setIsOpen={setIsMoveOutDialogOpen}
        handleMoveOutTenant={handleMoveOutTenant}
      />
      <SnackbarAlert
        type={error ? 'error' : 'success'}
        time={5000}
        text={error || success || ''}
        isOpen={!!error || !!success}
        onClose={() => {
          setError(null);
          setSuccess(null);
        }}
      />
    </>
  );
};

const styles = {
  noRows: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
};

export default TenantsDatagrid;
