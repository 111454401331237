import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IEditedChangesDialog {
  showUnsavedChangesDialog: boolean;
  setShowUnsavedChangesDialog: React.Dispatch<React.SetStateAction<boolean>>;
  nextTabIndexRef: React.MutableRefObject<number>;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

const EditedChangesDialog = ({
  showUnsavedChangesDialog,
  setTabIndex,
  nextTabIndexRef,
  setShowUnsavedChangesDialog
}: IEditedChangesDialog) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={showUnsavedChangesDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('Save_the_changes')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t('Discard_text')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'text'}
          onClick={() => {
            // Get rid of the modal dialog, then set the new tab index
            new Promise<void>((resolve): void => {
              setShowUnsavedChangesDialog(false);
              resolve();
            }).then(() => {
              setTabIndex(nextTabIndexRef.current);
            });
          }}
        >
          {t('Discard_Changes')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setShowUnsavedChangesDialog(false);
          }}
        >
          {t('Keep_Editing')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditedChangesDialog;
