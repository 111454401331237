import { QuikspecCategory, SystemConstraint } from 'features/QuikSpec/Types/QuikSpecTypes';

/**
 * KB Series QuikSpec Categories and items
 *
 */
export const kbMasterStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Master Stations',
    description: 'Please select up to 5 Master stations',
    items: [
      { deviceName: 'KB-3MRD', defaultQuantity: 1, readOnly: true, maxQuantityAllowedPerItem: 1 },
      { deviceName: 'KB-3HRD', maxQuantityAllowedPerItem: 4 },
      { deviceName: 'KB-3SD', maxQuantityAllowedPerItem: 4, discontinued: true }
    ],
    maxQuantityAllowedPerCategory: 5
  }
];

export const kbDoorStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Audio/Video Door Stations',
    description: 'Please select up to 3 Door stations',
    items: [
      { deviceName: 'KB-DAR', maxQuantityAllowedPerItem: 3 },
      { deviceName: 'KB-DAR-M', maxQuantityAllowedPerItem: 3 }
    ],
    maxQuantityAllowedPerCategory: 3
  },
  {
    id: 2,
    title: 'Audio Only Door Stations',
    description: 'Please select up to 3 Door stations',
    items: [
      { deviceName: 'AX-DM', maxQuantityAllowedPerItem: 3 },
      { deviceName: 'IE-JA', maxQuantityAllowedPerItem: 3 },
      { deviceName: 'IE-SS/A', maxQuantityAllowedPerItem: 3 },
      { deviceName: 'IE-SSR', maxQuantityAllowedPerItem: 3 },
      { deviceName: 'IF-DA', maxQuantityAllowedPerItem: 3 }
    ],
    maxQuantityAllowedPerCategory: 3
  }
];

export const kbAccessories: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Accessories',
    description: 'Optional Accessories.',
    items: [
      { deviceName: 'MCW-S/B', maxQuantityAllowedPerItem: 5 },
      { deviceName: 'KA-DGR', maxQuantityAllowedPerItem: 3 },
      { deviceName: 'RY-1824L', maxQuantityAllowedPerItem: 1 },
      { deviceName: 'RY-3DL', maxQuantityAllowedPerItem: 1 },
      { deviceName: 'IER-2', maxQuantityAllowedPerItem: 1 },
      { deviceName: 'RY-ES', maxQuantityAllowedPerItem: 1 }
    ]
  }
];

// Hidden products are designed not to be displayed.
export const kbHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [{ deviceName: 'PS-2420UL' }]
  }
];

export const kbSystemConstraints: SystemConstraint[] = [
  { description: 'Master & Sub-Master Stations allowed', max: 5 },
  { description: 'Door Stations allowed', max: 3 }
];
